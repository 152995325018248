import React from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';

const MoreButton = ({ children, ...props }) => (
  <EmptyButton
    padding="7px 13px"
    borderRadius="30px"
    fontSize="14px"
    color="primary"
    fontWeight="600"
    _hover={{
      backgroundColor: 'background200',
    }}
    {...props}
  >
    {children}
  </EmptyButton>
);

MoreButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MoreButton;
