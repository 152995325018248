import { createContext, PropsWithChildren, useContext } from 'react';
import useManageProfileValues, { ManageProfileValues } from './hooks';
import { userInfo } from '../../lib/util';
import UpgradePlanDialog from '../../components/UI/molecules/UpgradePlanDialog';

const Context = createContext<ManageProfileValues | Record<string, never>>({});

const Provider = ({ children }: PropsWithChildren) => {
  const value = useManageProfileValues();
  const {
    media,
    user,
    profile,
    upgradeDialog: { showUpgrade, openUpgrade, closeUpgrade, upgradeCtaPosition },
  } = value;
  const { displayName } = profile.profile || {};
  const { desktop, mobile } = media || {};
  const { email, firstName, lastName, phoneNumber } = userInfo(user?.user?.account || {});
  return (
    <Context.Provider value={value}>
      {children}
      <UpgradePlanDialog
        email={email}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        displayName={displayName}
        desktop={desktop}
        mobile={mobile}
        show={showUpgrade}
        onClose={() => closeUpgrade()}
        onSuccess={openUpgrade}
        upgradeCtaPosition={upgradeCtaPosition}
      />
    </Context.Provider>
  );
};

/**
 * Provides managed profile context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   profile,
 *   notifications,
 *   userProfiles,
 * } = useManageProfileContext();
 */
function useManageProfileContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useManageProfileContext must be used within a MangeProfileContext Provider');
  }

  return context;
}

export default useManageProfileContext;
export { Provider, Context };
