import { gql } from '@apollo/client';

const pardotUpgradeContactMutation = gql`
  mutation pardotUpgradeContact($data: JSONObject!) {
    pardotUpgradeContact(data: $data) {
      status
    }
  }
`;

export default pardotUpgradeContactMutation;
