import { gql } from '../../__generated__';

const markAllNotificationsAsRead = gql(`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      readTime
    }
  }
`);

export default markAllNotificationsAsRead;
