import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@apollo/client';
import { UpdateAwsAccountPayload } from '../../__generated__/graphql';

interface UpdateAwsAccount {
  updateAwsAccount: UpdateAwsAccountPayload;
}

const updateAwsAccount = gql(`
    mutation updateAwsAccount($input: UpdateAwsAccountInput!) {
        updateAwsAccount(input: $input) {
            awsAccount {
                ...AwsAccountFields
            }
        }
    }
`) as TypedDocumentNode<UpdateAwsAccount>;

export default updateAwsAccount;
