import { gql } from '@apollo/client';

const verifyEmail = gql`
  mutation VerifyEmail($input: verifyEmailInput!) {
    verifyEmail(input: $input) {
      expiresAt
      token
    }
  }
`;

export default verifyEmail;
