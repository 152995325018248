import { gql } from '@apollo/client';

const profileHistory = gql`
  query profileHistory(
    $profileId: String!
    $first: Int
    $after: String
    $startAt: DateTime
    $endAt: DateTime
  ) {
    profileHistory(
      profileId: $profileId
      first: $first
      after: $after
      startAt: $startAt
      endAt: $endAt
    ) {
      edges {
        node {
          id
          delta
          action
          createdAt
          updatedAt
          updatedBy
          user {
            id
            displayName
            avatar
            userRoles {
              edges {
                node {
                  role {
                    name
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default profileHistory;
