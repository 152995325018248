import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@apollo/client';
import { CreateProfileInvitationPayload } from '../../__generated__/graphql';

interface CreateProfileInvitation {
  createProfileInvitation: CreateProfileInvitationPayload;
}

const createProfileInvitation = gql`
  mutation createProfileInvitation($input: CreateProfileInvitationInput!) {
    createProfileInvitation(input: $input) {
      profileInvitation {
        id
        name
        email
        createdAt
        expiresAt
        profileRoles {
          profileId
          profileRoleName
          profile {
            logo
            id
          }
        }
      }
    }
  }
` as TypedDocumentNode<CreateProfileInvitation>;

export default createProfileInvitation;
