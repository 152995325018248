import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '../../__generated__/gql';
import { CurrentUserQuery } from '../../__generated__/graphql';

const user = gql(`
  query CurrentUser {
    currentUser {
      ...UserFields
    }
  }
`) as TypedDocumentNode<CurrentUserQuery>;

export default user;
