import { CSSProperties, useEffect, useState } from 'react';
import Dialog from 'ls-common-client/src/components/Dialog';
import { styled } from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Plan from './Plan';
import plan1 from '../../../../images/plan1.svg';
import plan2 from '../../../../images/plan2.svg';
import plan3 from '../../../../images/plan3.svg';
import plan4 from '../../../../images/plan4.svg';
import plan5 from '../../../../images/plan5.svg';
import plan6 from '../../../../images/plan6.svg';
import upgradeSuccessIcon from '../../../../images/upgradeSuccessIcon.svg';
import callRequested from '../../../../images/callRequested.svg';
import Button from '../../atoms/Button';
import { FormDialogSubmit } from '../FormDialog';
import { pardotUpgradeContactMutation, pardotUpgradeMutation } from '../../../../graphql/mutations';
import { PlanObj } from '../../../../types/upgradePlan';
import useDataLayerPush from '../../../../hooks/useDataLayerPush';
import { TRACKING } from '../../../../lib/constants';

const StyledDialog = styled(Dialog)`
  @media (max-width: 1400px) {
    width: 100vw;
    height: 100%;
  }
`;

const StyledContainer = styled(Container)`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 10px;
  }
`;

const plans = [
  {
    name: 'Localsearch for Business',
    price: '69',
    features: ['1 Primary Category', 'Free Bronze Starters', 'Service Areas', 'Banner Ad'],
    image: plan1,
  },
  {
    name: 'Localsearch Plus',
    price: '99',
    features: ['NAP Syncing', 'Profile Optimisation', 'Trading Hours', 'Monthly Posting'],
    image: plan2,
  },
  {
    name: 'SEO',
    price: '999',
    features: ['In-depth SEO Audit', 'Technical analysis', 'GBP Posting', 'Monthly Content'],
    image: plan3,
  },
  {
    name: 'Performance Boost',
    price: '499',
    features: [
      'Multi-channel advertising',
      'Awareness & Traffic',
      'Harnessing AI',
      'Ad Optimisation',
    ],
    image: plan4,
  },
  {
    name: 'Smart Websites',
    price: '99',
    features: ['24/7 Presence', 'Mobile Responsive', 'SEO Friendly', 'Fast & Secure'],
    image: plan5,
  },
  {
    name: 'Google Ads',
    price: '499',
    features: [
      'Performance Reporting',
      'Ad Optimisation',
      'Website Tracking',
      'Conversion Focused',
    ],
    image: plan6,
  },
];

interface UpgradePlanDialogProps extends CSSProperties {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  displayName?: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  desktop: boolean;
  mobile: boolean;
  upgradeCtaPosition: string;
}

const UpgradePlanDialog = ({
  show,
  onClose,
  onSuccess,
  displayName,
  email,
  firstName,
  lastName,
  phoneNumber,
  desktop,
  mobile,
  upgradeCtaPosition,
  ...props
}: UpgradePlanDialogProps) => {
  const [showUpgradeSuccess, setShowUpgradeSuccess] = useState<boolean>(false);
  const [showCallSuccess, setShowCallSuccess] = useState<boolean>(false);
  const [upgradeLoading, setUpgradeLoading] = useState<boolean>(false);
  const [callLoading, setCallLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [planSelected, setPlanSelected] = useState<boolean>(false);
  const [formStart, setFormStart] = useState<boolean>(false);
  const [upgradeSubmitClicked, setUpgradeSubmitClicked] = useState<boolean>(false);
  const client = useApolloClient();
  const { profileId } = useParams();
  const { dataLayerPush } = useDataLayerPush();

  const dataLayerParams = {
    index: 'Step-1',
    form_type: TRACKING.FORM_TYPES.UPGRADE,
    step_name: TRACKING.STEP.PACKAGE_SELECTION,
    cta_position_on_page: upgradeCtaPosition,
  };

  useEffect(() => {
    if (selectedItems.length) {
      setPlanSelected(false);
    } else {
      setPlanSelected(true);
    }
  }, [selectedItems]);

  const onUpgradeSubmit = async () => {
    if (!selectedItems.length) {
      setPlanSelected(true);
      setUpgradeSubmitClicked(true);
      dataLayerPush({
        event: TRACKING.EVENTS.VALIDATION_ERROR,
        cta_text: `${dataLayerParams.step_name}_'Please select at least one solution to request an upgrade'`,
        ...dataLayerParams,
      });
    } else {
      setUpgradeLoading(true);
      const data = {
        company: displayName,
        email,
        firstName,
        lastName,
        phone: phoneNumber || undefined,
        enquiry_reason: 'upgrade',
        upgrade_type: selectedItems,
        profile_id: profileId || undefined,
      };
      await client.mutate({
        mutation: pardotUpgradeMutation,
        variables: {
          data,
        },
      });
      setUpgradeLoading(false);
      dataLayerPush({
        event: TRACKING.EVENTS.REQUEST_UPGRADE,
        cta_text: TRACKING.EVENTS.REQUEST_UPGRADE,
        ...dataLayerParams,
      });
      setShowUpgradeSuccess(true);
      onSuccess();
      setSelectedItems([]);
      setUpgradeSubmitClicked(false);
    }
  };

  const onCallSubmit = async () => {
    setCallLoading(true);
    const data = {
      company: displayName,
      email,
      firstName,
      lastName,
      phone: phoneNumber || undefined,
    };
    await client.mutate({
      mutation: pardotUpgradeContactMutation,
      variables: {
        data,
      },
    });
    dataLayerPush({
      event: TRACKING.EVENTS.REQUEST_CALL,
      cta_text: TRACKING.EVENTS.REQUEST_CALL,
      ...dataLayerParams,
    });
    setCallLoading(false);
    setShowCallSuccess(true);
    onSuccess();
    setSelectedItems([]);
  };
  const toggleSelection = (itemName: string) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemName));
    } else {
      setSelectedItems([...selectedItems, itemName]);
    }
  };
  const handleSelectionChange = (item: PlanObj) => {
    if (!formStart) {
      dataLayerPush({
        event: TRACKING.EVENTS.FORM_START,
        cta_text: item.name,
        ...dataLayerParams,
      });
      setFormStart(true);
    }
    toggleSelection(item.name);
  };
  const pushFormCloseEvent = () => {
    dataLayerPush({
      event: TRACKING.EVENTS.FORM_CLOSED,
      ...dataLayerParams,
    });
    setFormStart(false);
  };
  const handleClose = () => {
    setSelectedItems([]);
    setUpgradeSubmitClicked(false);
    onClose();
  };
  return (
    <>
      <StyledDialog
        show={show}
        clickOutside
        onClose={handleClose}
        padding={desktop ? '40px 40px 20px 40px' : '24px'}
        maxWidth="100%"
        margin="0 auto"
        width="1230px"
        borderRadius={desktop ? '20px' : undefined}
        mode={desktop ? 'desktop' : 'mobile'}
        {...props}
      >
        <Container display="flex" justifyContent="end" alignItems="center">
          <EmptyButton
            display="flex"
            alignItems="flex-end"
            onClick={() => {
              handleClose();
              pushFormCloseEvent();
            }}
          >
            <Icon className="lsDesignIcon-x-bold" color="#000" marginTop="5px" fontSize="20px" />
          </EmptyButton>
        </Container>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize={desktop ? '36px' : '30px'} fontWeight="700" color="#4a4a4a">
            Find the right solution to grow your business
          </Text>
          <Container marginTop="10px">
            <Text fontSize="16px" fontWeight="500" color="#4a4a4a">
              You can select multiple solutions to request an upgrade.
            </Text>
          </Container>
        </Container>
        <StyledContainer
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="20px 0px 20px 0px"
          overflowX="auto"
          marginRight={desktop ? '-40px' : undefined}
        >
          {plans.map((plan, index) => (
            <Plan
              key={plan.price}
              plan={plan}
              index={index}
              isActive={selectedItems.includes(plan.name)}
              onClick={() => handleSelectionChange(plan)}
              {...props}
            />
          ))}
        </StyledContainer>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="20px 0"
          flexDirection="column"
          textAlign="center"
        >
          <Text fontSize="16px" color="#4a4a4a" fontWeight="450">
            Alternatively, one of our local business marketing specialists can give you a call to
            talk about the best solution for your business.
          </Text>
          <Container
            margin="5px 0 0 0"
            visibility={planSelected && upgradeSubmitClicked ? 'visible' : 'hidden'}
          >
            <Text fontSize="14px" color="#F16159" fontWeight="450">
              Please select at least one solution to request an upgrade.
            </Text>
          </Container>
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            gap="20px"
            flexDirection={mobile ? 'column' : undefined}
            width={mobile ? '95%' : undefined}
          >
            <FormDialogSubmit
              onClick={() => onCallSubmit()}
              padding="0 20px"
              fontSize="16px"
              fontWeight="600"
              minWidth="80px"
              height="44px"
              loading={callLoading}
              background="#F8F8F8"
              color="primary"
              _hover={{
                background: '#ECECEC',
              }}
              loaderProps={
                callLoading ?
                  {
                    background: '#F8F8F8',
                  }
                : undefined
              }
            >
              Request a Call
            </FormDialogSubmit>
            <FormDialogSubmit
              onClick={() => onUpgradeSubmit()}
              padding="0 20px"
              fontSize="16px"
              fontWeight="600"
              minWidth="80px"
              height="44px"
              loading={upgradeLoading}
              background="primary"
              color="white"
              _hover={{
                background: 'linear-gradient(180deg, #98B0FD -45.26%, #2D60FB 100%)',
              }}
              loaderProps={
                upgradeLoading ?
                  {
                    background: '#F8F8F8',
                  }
                : undefined
              }
            >
              Request an upgrade
            </FormDialogSubmit>
          </Container>
        </Container>
      </StyledDialog>
      <Dialog
        show={showUpgradeSuccess || showCallSuccess}
        maxWidth="408px"
        clickOutside
        onClose={() => setShowUpgradeSuccess(false)}
        mode={desktop ? 'desktop' : 'mobile'}
      >
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="30px"
          flexDirection="column"
          height={!desktop ? '100%' : undefined}
        >
          <Container width="100%" marginBottom="20px" display="flex" justifyContent="end">
            <EmptyButton
              onClick={() => {
                if (showUpgradeSuccess) {
                  setShowUpgradeSuccess(false);
                } else {
                  setShowCallSuccess(false);
                }
              }}
              display="flex"
              alignItems="center"
            >
              <Icon fontSize="20px" color="normal" className="lsDesignIcon-x-bold" />
            </EmptyButton>
          </Container>
          <Container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Image margin="15px 0" src={showCallSuccess ? callRequested : upgradeSuccessIcon} />
            <Text fontSize="26px" fontWeight="700">
              {showUpgradeSuccess ? 'Upgrade Request Sent!' : 'Call Requested!'}
            </Text>
            <Container margin="15px 0 65px 0">
              <Text display="flex" textAlign="center" fontSize="16px" fontWeight="450">
                {showUpgradeSuccess ?
                  'Thank you for your interest in upgrading. Our dedicated specialist will reach out to you within the next 24 hours.'
                : 'One of our local business marketing specialists will reach out to you within the next 24 hours.'
                }
              </Text>
            </Container>
          </Container>
          <Button
            buttonStyle="secondary"
            marginTop="10px"
            margin="15px 0"
            onClick={() => {
              if (showUpgradeSuccess) {
                setShowUpgradeSuccess(false);
              } else {
                setShowCallSuccess(false);
              }
            }}
            width={!desktop ? '95%' : undefined}
          >
            Done
          </Button>
        </Container>
      </Dialog>
    </>
  );
};

export default UpgradePlanDialog;
