import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { awsAccount as awsAccountQuery } from '../graphql/queries';

const useAwsAccount = () => {
  const { awsAccountId } = useParams();

  const [fetch, { data, loading }] = useLazyQuery(awsAccountQuery);

  useEffect(() => {
    if (awsAccountId) {
      fetch({
        variables: {
          id: awsAccountId,
        },
      });
    }
  }, [awsAccountId]);

  return {
    loading,
    awsAccount: data?.awsAccount,
  };
};

export default useAwsAccount;
