import { gql } from '@apollo/client';

const updateProfileMutation = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        displayName
        logo
        vanityUrlPath
        about
        establishedAt
        street
        suburb
        stateCode
        postcode
        countryCode
        coordinates
        status
        reviewStatus
        claimed
        temporaryClosed
      }
    }
  }
`;

export default updateProfileMutation;
