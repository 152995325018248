const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const ROLES = {
  STAFF: 'staff',
  PERSONIV: 'personiv',
};

const AWS_ACCOUNT_ROLES = {
  ADMIN: 'admin',
  OWNER: 'owner',
};

const PROFILE_ROLES = {
  EDITOR: 'editor',
};

const TRADING_HOURS_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed',
  TWENTY_FOUR_HOURS: '24hrs',
  OPEN_BY_APPOINTMENT: 'openByAppointment',
};

const CONTACT_TYPES = {
  PHONE: 'phone',
  SOCIAL: 'social',
  EMAIL: 'email',
  URL: 'url',
  CUSTOM_URL: 'customUrl',
};

const SOCIAL_LABELS = {
  GBP: 'gbp',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedIn',
  INSTAGRAM: 'instagram',
  X: 'x',
  PINTEREST: 'pinterest',
  SKYPE: 'skype',
  TUMBLR: 'tumblr',
  YOUTUBE: 'youTube',
  TIKTOK: 'tikTok',
};

const PHONE_LABELS = {
  PRIMARY_PHONE: 'primaryPhone',
};

const URL_LABELS = {
  WEBSITE: 'website',
  BOOKING: 'booking',
};

const PAYMENT_TYPES = {
  ACCOUNT: 'account',
  AFTERPAY: 'afterPay',
  AMEX: 'amex',
  APPLE_PAY: 'applePay',
  BARTERCARD: 'bartercard',
  BITCOIN: 'bitcoin',
  BPAY: 'bpay',
  BULK_BILLING: 'bulkBilling',
  CASH: 'cash',
  CHEQUE: 'cheque',
  DIRECT_DEBIT: 'directDebit',
  EFT: 'eft',
  EFTPOS: 'eftpos',
  FULLY_INSURED: 'fullyInsured',
  GIFT_VOUCHER: 'giftVoucher',
  GOOGLE_PAY: 'googlePay',
  HICAPS: 'hicaps',
  HUMM: 'humm',
  INTEREST_FREE_PLANS: 'interestFreePlans',
  MASTERCARD: 'mastercard',
  OPENPAY: 'openpay',
  PAYPAL: 'payPal',
  SENIORS_DISCOUNT: 'seniorsDiscount',
  VISA: 'visa',
  ZIP_PAY: 'zipPay',
  MEDI_PAY: 'mediPay',
  QOIN: 'qoin',
  PAY_ID: 'payId',
};

const PRODUCT_TYPES = {
  PRODUCT: 'product',
  SERVICE: 'service',
};

const STATES = {
  NSW: 'NSW',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
  NT: 'NT',
  ACT: 'ACT',
};

const MEDIA_TYPES = {
  IMAGE: 'image',
  YOUTUBE: 'youTube',
  VIMEO: 'vimeo',
  DOCUMENT: 'document',
};

const LISTING_POST_THEMES = {
  LS_GRADIENT: 'lsGradient',
  INTO_THE_DEEP: 'intoTheDeep',
  SUMMER_PINEAPPLE: 'summerPineapple',
  MAGIC_MINT: 'magicMint',
  SWEET_JASMINE: 'sweetJasmine',
  AQUA_SPECTACULAR: 'aquaSpectacular',
};

const LISTING_POST_STATES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

const QUOTE_SERVICE = {
  INSTALLATION: 'installation',
  REPAIR: 'repair',
  ONGOING: 'ongoing',
  ONE_OFF: 'oneOff',
  COMMERCIAL: 'commercial',
  OTHER: 'other',
};

const PROFILE_STATUSES = {
  PENDING: 'pending',
  PUBLISHED: 'published',
  DISABLED: 'disabled',
  LOCKED: 'locked',
};

const REVIEW_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
};

const MAX_UPLOAD_SIZE = 1024 * 1024 * 20;

const MAX_SIZE = {
  PROFILE: {
    DISPLAY_NAME: 80,
    ABOUT: 2000,
    FAQ_QUESTION: 180,
    FAQ_ANSWER: 400,
  },
};

const TRACKING = {
  EVENTS: {
    UPDATE_CLICKED: 'Update Clicked',
    REQUEST_UPGRADE: 'Request an Upgrade',
    REQUEST_CALL: 'Request a Call',
    PAGEVIEW: 'PageView',
    UPGRADE_CLICKED: 'Upgrade Clicked',
    PREV_BUTTON_CLICKED: 'Prev Button Clicked',
    NEXT_BUTTON_CLICKED: 'Next Button Clicked',
    DOT_NAVIGATION_CLICKED: 'Tutorial Popup-Dot Navigation Clicked',
    TUTORIAL_POPUP_CLOSED: 'Tutorial Popup Closed',
    USER_TUTORIAL_SHOWN: 'User Tutorial Shown',
    CREATE_PROFILE: 'BPM - Create Profile Form Submission',
    MENU_NAVIGATION: 'Menu Selection',
    FORM_SUBMIT: 'Form Submit',
    INBOX_OWNER_REPLY_FORM_SUBMIT: 'Inbox- Owner Reply Form Submit',
    INBOX_OWNER_LINK_CLICK: 'Inbox- Owner Reply Link Clicked',
    ADD_BUSINESS: 'Add Business Clicked',
    VIEW_PROFILE: 'View Profile',
    SHARE_REVIEWS_LINK_CLICK: 'Share Review Link Clicked',
    SHARE_LINK_CLICKED: 'Share Link Clicked',
    FILTER_BY_DATE: 'Filter By Date',
    REVIEWS_POST_REPLY: 'Reviews - Post Reply',
    FORM_START: 'Form Start',
    FORM_CLOSED: 'Form Closed',
    VALIDATION_ERROR: 'Error/Validation Message Shown',
    CONTACT_US_LINK: 'Contact Us Clicked',
    RESEND_CODE: 'Resend Code Clicked',
    EXISTING_BUSINESS_POPUP: 'Existing Business Profile Pop-up Shown',
    EXISTING_BUSSINESS_POPUP_DISMISS: 'Existing Business Profile Pop-up Dismissed',
    NEXT_CTA_CLICKED: 'Next CTA Clicked',
    EXISTING_BUSSINESS_POPUP_CLOSED: 'Existing Business Profile Pop-up Closed',
  },
  CTA: { SUBMIT: 'Submit' },
  STEP: {
    OTP_VERIFICATION: 'OTP verification',
    VERIFICATION_OPTIONS: 'verification options',
    PACKAGE_SELECTION: 'package_selection',
  },
  CONFIGURATION: {
    PROFILE_DETAILS: 'Profile Details',
    TRADING_HOURS: 'Trading Hours',
    CONTACT_INFORMATION: 'Contact Information',
    PRODUCTS_SERVICES_AND_MORE: 'Products Services and More',
    PAYMENT_METHODS: 'Payment Methods',
    SOCIAL_ACCOUNTS: 'Social Accounts',
    AMENITIES: 'Amenities',
    POSTS: 'Posts',
    DASHBOARD_HOME: 'Dashboard-Home',
    MENU_SECTION: 'Menu-Section',
    PRIMARY_LOCATION: 'Primary Location',
    CATEGORIES: 'Categories',
    EMAIL_SETTINGS: 'Email Settings',
  },
  FORM_TYPES: {
    UPGRADE: 'Upgrade',
    CONTACT_US: 'Contact Us',
    CREATE_PROFILE: 'add_a_business',
    CLAIM_PROFILE: 'claim_profile',
    OWNER_REVIEW: 'owner review form',
  },
  PAGE_TYPES: {
    INBOX_QUOTES: 'Inbox_quotes',
    INBOX_MESSAGES: 'Inbox_messages',
    HOMEPAGE: 'Homepage',
  },
  CTA_POSITION: {
    PROFILE_INFO_CARD: 'profile info card',
    REVIEWS: 'Reviews',
    REVIEW_SCORE_CARD: 'review score card',
    PAGE_HEADER: 'Page Header',
    MODAL_POPUP: 'Modal Popup',
  },
};
const CHART_COLORS = [
  { from: '#FF7F00', to: '#FFBE00', solid: '#FF9900' },
  { from: '#98B0FD', to: '#2D60FB', solid: '#285AFF' },
  { from: '#4092A4', to: '#61E7E7', solid: '#46C4E0' },
  { from: '#849AE8', to: '#EE7AEB', solid: '#B164FF' },
  { from: '#FFE38F', to: '#FFBE00', solid: '#FFC414' },
  { from: '#40A499', to: '#61E7D9', solid: '#40A499' },
  { from: '#F16159', to: '#B164FF', solid: '#FF3AA9' },
  { from: '#119000', to: '#95E08B', solid: '#71C666' },
  { from: '#166C95', to: '#96D2EE', solid: '#166C95' },
  { from: '#97AEFF', to: '#8EDEF8', solid: '#97AEFF' },
];

const DESCRIPTION = {
  LOGO: 'Having a compelling and recognizable logo is crucial for making a strong first impression. Upload a high-quality logo that represents your brand and ensures it fits perfectly within the provided dimensions.',
  UPLOAD_MEDIA:
    'Enrich your profile with eye-catching media, such as photos and videos, that showcase your products, services, or premises. High-resolution images and engaging videos can captivate potential customers.',
  DISPLAY_NAME:
    'Your display name is the first thing users see, so make sure it accurately reflects your business. Keep it concise and easy to remember.',
  ESTABLISHED:
    'Highlight the date when your business was established. Demonstrating longevity can enhance trust among potential customers.',
  FREQUENTLY_ASK_QUESTIONS:
    'Anticipate your customers queries by providing helpful FAQs. Clear and comprehensive answers can save time for both you and your visitors.',
  ABOUT_US:
    'Tell your unique story and what sets your business apart. A compelling About Us section can create a connection with your audience and build trust.',
  TRADING_HOURS:
    'Ensure your businesss operating hours are up-to-date. This information helps customers know when they can visit or contact you.',
  CONTACTS:
    'Double-check that your contact details, including phone numbers and email addresses, are accurate. Accessibility is essential for potential clients.',
  PRODUCTS:
    'List your products or services with detailed descriptions and appealing images. Highlighting what you offer helps customers understand your offerings better.',
  LICENSES:
    'If applicable, display any relevant licenses or certifications. This enhances your credibility and assures customers of your qualifications.',
  PAYMENT_TYPES:
    'Specify the payment methods you accept. Being transparent about payment options helps customers plan their transactions.',
  SOCIAL_MEDIA_PROFILES:
    'Link your social media accounts to your profile. Social media presence adds authenticity and provides customers with another way to engage with your brand.',
  AMENITIES:
    'If your business has physical locations, outline the amenities available to customers. This can include features like parking, Wi-Fi, accessibility, etc.',
  CONNECTED_APPS:
    'Seamlessly integrate and manage key platforms like Google ensuring unified reporting and easy updates for your business information.',
  TOTAL_LEADS:
    'The cumulative number of enquiries and potential customer interactions generated directly from your Localsearch & Google business profile.',
  WEBSITE_TRAFFIC:
    'The overall volume of visitors who have accessed your website, giving you insights into your online reach and engagement.',
  SOCIAL_IMPRESSION:
    "Track the number of times your content has been displayed on user's screens, indicating potential reach.",
  TOTAL_CONVERSION:
    'The total count of desired actions completed by users, such as sign-ups, purchases, or inquiries, resulting from your Google Ads campaign efforts.',
  PROFILE_HEALTH_SCORE:
    'Profile health score is calculated based on profile completeness. Improve your score and boost your business visibility and engagement.',
  TEMPORARY_CLOSED: 'Show your business will open again in the future.',
  TOTAL_VIEWS: 'Track the total number of times your business profile has been viewed by visitors.',
};

const POST_TEXT_TYPES = {
  BOOK: 'Book',
  ORDER_ONLINE: 'Order Online',
  BUY: 'Buy',
  LEARN_MORE: 'Learn More',
  SIGN_UP: 'Sign Up',
  CALL_NOW: 'Call Now',
};

const STAFF_QUERY_PARAMS = {
  PROFILE_IDS: 'profileIds',
  ROLE_NAME: 'roleName',
};

const TOOLTIP_KEYS = {
  IGNORED_MEDIA_OVER_3YEARS: 'ignoredMediaOver3Years',
};
// Refer https://docs.google.com/document/d/1SOJiQYlXNN0S1Wk-owumIK9RSbNd7lM5AgJ-OGk9iFg/edit
const SWEAR_WORDS = [
  'fuck',
  'fucking',
  'fucked',
  'fucker',
  'motherfucker',
  'fuckstick',
  'fuckwit',
  'shit',
  'shitting',
  'shitted',
  'bullshit',
  'crap',
  'crapping',
  'cunt',
  'cunted',
  'cunts',
  'cunty',
  'cuntface',
  'dick',
  'dickhead',
  'dick head',
  'dick-head',
  'dickface',
  'wanker',
  'cock',
  'pussy',
  'asshole',
  'ass hole',
  'ass-hole',
  'arsehole',
  'arse hole',
  'arse-hole',
  'ahole',
  'a-hole',
  'bastard',
  'nigga',
  'nigger',
  'niga',
  'koon',
  'coon',
  'gronk',
  'pome',
  'pommy',
  'abo',
  'abbo',
  'wog',
  'redskin',
  'kaffir',
  'kafir',
  'negro',
  'negress',
  'golliwogg',
  'chinaman',
  'chink',
  'beaner',
  'faggot',
  'whore',
  'tbagger',
  't-bagger',
  't bagger',
  'tea bagger',
  'gook',
  'dyke',
  'boong',
  'blowjob',
  'chingchong',
  'ching-chong',
  'ching chong',
  'cum',
  'ballbag',
  'jizz',
  'homo',
  'slant-eye',
  'slant eye',
  'honky',
  'twink',
  'hori',
  'crackwhore',
  'crack whore',
  'yellow bone',
  'yellow-bone',
  'yellowbone',
  'chode',
  'gimp',
];
const DEFAMATORY_LANGUAGE = [
  'scam',
  'scammer',
  'scammed',
  'racist',
  'sexist',
  'pedophile',
  'rape',
  'molester',
  'conned',
  'pothead',
  'marijuana',
  'cocaine',
  'methamphetamine',
  'mdma',
  'nangs',
  'heroin',
  'flakka',
  'ecstasy',
  'krokodil',
];
const BAD_WORDS = [...SWEAR_WORDS, ...DEFAMATORY_LANGUAGE];

const STATUS_NOTE_NAME = 'profileStatus';

const HISTORY_TAG_COLOURS = {
  note: '#B164FF',
  updated: '#71C666',
};

export {
  DAYS_OF_THE_WEEK,
  TRADING_HOURS_TYPES,
  CONTACT_TYPES,
  SOCIAL_LABELS,
  URL_LABELS,
  PAYMENT_TYPES,
  PHONE_LABELS,
  PRODUCT_TYPES,
  STATES,
  MEDIA_TYPES,
  LISTING_POST_THEMES,
  LISTING_POST_STATES,
  QUOTE_SERVICE,
  PROFILE_STATUSES,
  REVIEW_STATUSES,
  MAX_UPLOAD_SIZE,
  MAX_SIZE,
  ROLES,
  TRACKING,
  DESCRIPTION,
  AWS_ACCOUNT_ROLES,
  PROFILE_ROLES,
  CHART_COLORS,
  POST_TEXT_TYPES,
  STAFF_QUERY_PARAMS,
  TOOLTIP_KEYS,
  BAD_WORDS,
  HISTORY_TAG_COLOURS,
  STATUS_NOTE_NAME,
};
