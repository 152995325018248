import { gql } from '@apollo/client';

const category = gql`
  query category($id: String!) {
    category(id: $id) {
      id
      name
      relatedCategories {
        id
        name
      }
    }
  }
`;

export default category;
