import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';
import ErrorBoundary from '../../../UI/molecules/ErrorBoundary';

const Logo = lazy(() => import('../../../pages/Profile/Media/Logo'));
const Images = lazy(() => import('../../../pages/Profile/Media/Images'));
const Videos = lazy(() => import('../../../pages/Profile/Media/Videos'));
const Documents = lazy(() => import('../../../pages/Profile/Media/Documents'));

const mediaRoutes: NonIndexRouteObject[] = [
  {
    path: 'logo',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Logo />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'images',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Images />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'videos',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Videos />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'documents',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Documents />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export default mediaRoutes;
