import { gql } from '@apollo/client';

const pardotUpgradeMutation = gql`
  mutation pardotUpgrade($data: JSONObject!) {
    pardotUpgrade(data: $data) {
      status
    }
  }
`;

export default pardotUpgradeMutation;
