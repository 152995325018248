import { gql } from '../../__generated__/gql';

const awsAccount = gql(`
  query awsAccount($id: String!) {
    awsAccount(id: $id) {
      ...AwsAccountFields
    }
  }
`);

export default awsAccount;
