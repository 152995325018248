import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './ErrorBoundary.css';
import ErrorImg from '../../../../images/error-img.svg';
import { Context } from '../../../../context/GlobalContext';

interface ErrorModalProps {
  show: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ show }) => {
  if (!show) return null;
  const {
    contactUsDialog: { open },
  } = useContext(Context);
  const onClose = () => {
    window.location.reload();
  };

  return ReactDOM.createPortal(
    <div className="error-modal-overlay">
      <div className="error-modal">
        <div className="error-modal-close">
          <button type="button" onClick={onClose} aria-label="Close error modal">
            <span className="lsDesignIcon-x-bold" />
          </button>
        </div>
        <img alt="error" src={ErrorImg} />
        <span className="error-modal-title">We apologise for the inconvenience</span>
        <span className="error-modal-desc">
          Oops, looks like we hit an unexpected error. Please{' '}
          <span
            className="error-contact-us"
            onClick={() => open()}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            contact us
          </span>{' '}
          if this issue persists.
        </span>
        <button className="close-button" type="button" onClick={onClose}>
          Start Again
        </button>
      </div>
    </div>,
    document.body,
  );
};

export default ErrorModal;
