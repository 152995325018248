import { gql } from '../../__generated__';

const markNotificationsAsRead = gql(`
 mutation MarkNotificationsAsRead($notificationIds: [String], $resourceIds: [String]) {
  markNotificationsAsRead(notificationIds: $notificationIds, resourceIds: $resourceIds) {
    recipients {
      userId
      readTime
    }
  }
}
`);

export default markNotificationsAsRead;
