import { gql } from '@apollo/client';

const notifications = gql`
  query notifications(
    $resourceType: String
    $consumerType: String
    $consumerIds: [String]
    $first: Int
    $after: String
  ) {
    notifications(
      resourceType: $resourceType
      consumerType: $consumerType
      consumerIds: $consumerIds
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          body
          resource {
            id
            type
            action
          }
          author {
            id
            name
            avatar
          }
          consumer {
            id
            type
          }
          updatedAt
          createdAt
          recipients {
            userId
            readTime
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default notifications;
