import { gql } from '@apollo/client';

const pardotCreateProfile = gql`
  mutation PardotCreateProfile($data: JSONObject!) {
    pardotCreateProfile(data: $data) {
      status
    }
  }
`;

export default pardotCreateProfile;
