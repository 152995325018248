import Image from 'ls-common-client/src/components/Image';
import { Slide } from './Lightbox.types';

function renderThumbnail(slide: Slide) {
  if (!slide.thumbnail) {
    return null;
  }

  return (
    <Image alt={slide.title} objectFit="contain" src={slide.thumbnail} height="100%" width="100%" />
  );
}

export default renderThumbnail;
