import { gql } from '@apollo/client';

const setProfileMedia = gql`
  mutation setProfileMedia($input: SetProfileMediaInput) {
    setProfileMedia(input: $input) {
      media {
        url
        description
        type
        background
        createdAt
      }
    }
  }
`;

export default setProfileMedia;
