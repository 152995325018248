import { gql } from '@apollo/client';

const statisticalArea = gql`
  query statisticalArea($id: String!) {
    statisticalArea(id: $id) {
      id
      name
    }
  }
`;

export default statisticalArea;
