import { Suspense, lazy } from 'react';
import { NonIndexRouteObject } from 'react-router-dom';
import PageLoader from '../../UI/molecules/PageLoader';
import performanceRoutes from './performance';
import mediaRoutes from './media';
import inboxRoutes from './inbox';
import postsRoutes from './posts';
import { LightboxContextProvider } from '../../../context/LightboxContext';

import ErrorBoundary from '../../UI/molecules/ErrorBoundary';

const Home = lazy(() => import('../../pages/Profile/Home'));
const Info = lazy(() => import('../../pages/Profile/Info'));
const Posts = lazy(() => import('../../pages/Profile/Posts'));
const Reviews = lazy(() => import('../../pages/Profile/Reviews'));
const Inbox = lazy(() => import('../../pages/Profile/Inbox'));
const Media = lazy(() => import('../../pages/Profile/Media'));
const Performance = lazy(() => import('../../pages/Profile/Performance'));
const History = lazy(() => import('../../pages/Profile/History'));

const profileRoutes: NonIndexRouteObject[] = [
  {
    path: ':profileId',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Home />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: ':profileId/info',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Info />
      </Suspense>
    ),
  },
  {
    path: ':profileId/posts',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Posts />
      </Suspense>
    ),
    children: postsRoutes,
  },
  {
    path: ':profileId/reviews',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Reviews />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: ':profileId/inbox',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Inbox />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    children: inboxRoutes,
  },
  {
    path: ':profileId/media',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Media />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    children: mediaRoutes,
  },
  {
    path: ':profileId/performance',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Performance />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    children: performanceRoutes,
  },
  {
    path: ':profileId/history',
    element: (
      <Suspense fallback={<PageLoader />}>
        <History />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export default profileRoutes;
