import {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import FormCloseAlert from '../FormCloseAlert';

interface FormDialogContext {
  isDirty: boolean;
  setIsDirty: (value: boolean) => void;
  onCloseConfirm: () => void;
}

interface FormDialogProviderProps extends PropsWithChildren {
  onClose?: () => void;
}

const Context = createContext<FormDialogContext>({
  isDirty: false,
  setIsDirty: () => {},
  onCloseConfirm: () => {},
});

const Provider = ({ children, onClose = () => {} }: FormDialogProviderProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const onCloseConfirm = useCallback(() => {
    if (isDirty) {
      setShowAlertDialog(true);
    } else {
      onClose();
    }
  }, [isDirty]);

  const value = useMemo(
    () => ({
      setIsDirty,
      isDirty,
      onCloseConfirm,
    }),
    [isDirty, setIsDirty],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <FormCloseAlert
        show={showAlertDialog}
        onConfirm={() => {
          setShowAlertDialog(false);
          onClose();
        }}
        onCancel={() => setShowAlertDialog(false)}
      />
    </Context.Provider>
  );
};

const useFormDialogContext = () => useContext(Context);

export default useFormDialogContext;
export { Provider, Context };
