// eslint-disable-next-line import/no-extraneous-dependencies
import YARLightbox, {
  Plugin,
  Render,
  LightboxProps as YARLightboxProps,
} from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Download from 'yet-another-react-lightbox/plugins/download';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import styled from 'styled-components';
import { LightboxProps, Slide } from './Lightbox.types';
import 'yet-another-react-lightbox/styles.css';
import renderSlide from './renderSlide';
import renderThumbnail from './renderThumbnail';
import useCreateSlides from './useCreateSlides';

const StyledLightbox = styled(YARLightbox)`
  --yarl__portal_zindex: 99999999999; // Nothing should ever overlap the lightbox

  .yarl__slide_title {
    max-width: calc(100% - 190px); // Make room for the counter
  }
`;

const LightboxComponent = ({ onClose, media, show = false, index, options }: LightboxProps) => {
  const slides = useCreateSlides(media);

  if (!slides?.length) {
    return null;
  }

  const props = {
    close: onClose,
    open: show,
    index,
    carousel: {
      finite: true,
      preload: 2,
      padding: '16px',
      spacing: '30%',
      imageFit: 'contain',
      imageProps: {},
    },
    plugins: [Captions, Counter, Download, Slideshow, Thumbnails] as Plugin[],
    render: {
      slide: ({ slide, rect }) => renderSlide(slide as Slide, rect),
      thumbnail: ({ slide }) => renderThumbnail(slide as Slide),
    } as Render,
    slides,
    counter: {
      container: {
        style: {
          right: '150px',
          left: 'unset',
        },
      },
    },
    slideshow: { autoplay: true, delay: 10000 },
    thumbnails: {
      position: 'bottom',
      height: 80,
      width: 120,
      border: 1,
      borderRadius: 4,
      gap: 16,
      padding: 4,
      showToggle: false,
    },
    captions: { descriptionTextAlign: 'center' },
    ...options,
  } as unknown as YARLightboxProps; // FIXME: Fix this hacky typescript workaround

  return <StyledLightbox {...props} />;
};

export default LightboxComponent;
