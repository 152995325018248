import { useCallback, useState } from 'react';

export interface UseDisclosureProps {
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  close?(): void;
  open?(): void;
}

export function useDisclosure(props: UseDisclosureProps = {}) {
  const { defaultIsOpen, close: onCloseProp, open: onOpenProp, isOpen: isOpenProp } = props;

  const [isOpenState, setIsOpen] = useState(defaultIsOpen || false);

  const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;

  const isControlled = isOpenProp !== undefined;

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onCloseProp?.();
  }, [isControlled, onCloseProp]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenProp?.();
  }, [isControlled, onOpenProp]);

  return {
    isOpen,
    open,
    close,
    isControlled,
  };
}
