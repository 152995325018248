import { useCallback, useState } from 'react';

const useUpgradeDialog = () => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [upgradeCtaPosition, setUpgradeCtaPosition] = useState('');

  const openUpgrade = useCallback(
    (upgradeButtonPosition) => {
      setShowUpgrade(true);
      setUpgradeCtaPosition(upgradeButtonPosition);
    },
    [showUpgrade],
  );

  const closeUpgrade = useCallback(() => {
    setShowUpgrade(false);
  }, [showUpgrade]);

  return {
    showUpgrade,
    upgradeCtaPosition,
    openUpgrade,
    closeUpgrade,
  };
};

export default useUpgradeDialog;
