import { gql } from '@apollo/client';

const createProfileMutation = gql`
  mutation createProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      profile {
        id
        displayName
        vanityUrlPath
        establishedAt
        about
        coordinates
        street
        suburb
        stateCode
        postcode
        status
        logo
        reviewStatus
        temporaryClosed
        package {
          name
          id
        }
        products {
          name
          type
          highlight
        }
        licences {
          name
        }
        paymentTypes {
          name
        }
        amenities {
          id
        }
        categories {
          id
          name
          package {
            id
            name
            sort
          }
        }
        tradingHours {
          day
          start
          interval
          type
        }
        contacts {
          type
          label
          value
          primary
        }
        frequentlyAskedQuestions {
          question
          answer
        }
        media {
          url
          description
          type
          background
        }
        settings {
          reviewEmail
          quoteEmail
          reportEmail
          showAddress
          showReviews
          enquiryEmail
          enableEnquiryEmail
          enableQuoteEmail
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export default createProfileMutation;
