import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';
import { LightboxContextProvider } from '../../../../context/LightboxContext';
import ErrorBoundary from '../../../UI/molecules/ErrorBoundary';

const Enquiries = lazy(() => import('../../../pages/Profile/Inbox/Enquiries'));
const Quotes = lazy(() => import('../../../pages/Profile/Inbox/Quotes'));

const inboxRoutes: NonIndexRouteObject[] = [
  {
    path: 'messages',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Enquiries />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'enquiries',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Enquiries />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'quotes',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LightboxContextProvider>
          <Quotes />
        </LightboxContextProvider>
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export default inboxRoutes;
