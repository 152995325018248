import React from 'react';
import PropTypes from 'prop-types';
import AlertDialog from './AlertDialog';

const FormCloseAlert = ({ show, onConfirm, onCancel, ...props }) => (
  <AlertDialog
    id="close-dialog"
    show={show}
    heading="Are you sure you want to leave?"
    text="Your changes will not be saved."
    width="310px"
    buttons={[
      {
        children: 'Leave',
        props: {
          rounded: true,
          marginRight: '10px',
          onClick: onConfirm,
          backgroundColor: '#F8F8F8',
          _hover: {
            background: '#ECECEC',
            boxShadow: 'none',
          },
          color: '#285AFF',
        },
      },
      {
        children: 'Resume',
        props: {
          primary: true,
          rounded: true,
          onClick: onCancel,
          _hover: {
            background:
              'var(--badges-gradients-gradient-blue, linear-gradient(180deg, #98B0FD -45.26%, #2D60FB 100%))',
            boxShadow: 'none',
          },
        },
      },
    ]}
    {...props}
  />
);

FormCloseAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default FormCloseAlert;
