import { MutationFunctionOptions, useMutation } from '@apollo/client';
import {
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
  Notification,
  NotificationCount,
  NotificationCounts,
} from '../../../__generated__/graphql';
import { markNotificationsAsRead } from '../../../graphql/mutations';
import useUser from '../../../hooks/useUser';

const useMarkNotificationsAsRead = ({ notificationCounts }: UseMarkNotificationsAsReadParams) => {
  const { isStaff, isPersoniv } = useUser();

  const [mutate, { data, ...mutationResult }] = useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(markNotificationsAsRead);

  const markAsRead = async (
    resourceType: Notification['resource']['type'],
    options?: MutationFunctionOptions<
      MarkNotificationsAsReadMutation,
      MarkNotificationsAsReadMutationVariables
    >,
  ) => {
    if (!isStaff && !isPersoniv) {
      await mutate({
        ...(options ?? {}),
        update(cache, { data: mutateData }) {
          const totalMarkedAsRead = mutateData?.markNotificationsAsRead?.recipients?.length || 0;
          if (totalMarkedAsRead > 0) {
            cache.modify({
              fields: {
                notificationCounts() {
                  return {
                    totalCount:
                      notificationCounts?.totalCount ?
                        notificationCounts.totalCount - totalMarkedAsRead
                      : 0,
                    resourceTypes: (notificationCounts?.resourceTypes || []).reduce((agg, next) => {
                      if (next?.key === resourceType) {
                        return [
                          ...agg,
                          {
                            ...next,
                            value: next?.value ? next.value - totalMarkedAsRead : 0,
                          },
                        ];
                      }
                      return agg;
                    }, [] as NotificationCount[]),
                  };
                },
              },
            });
          }
        },
      });
    }
  };

  return {
    markAsRead,
    data: data?.markNotificationsAsRead,
    ...mutationResult,
  };
};

interface UseMarkNotificationsAsReadParams {
  notificationCounts: NotificationCounts | undefined | null;
}

export default useMarkNotificationsAsRead;
