import { gql } from '@apollo/client';

const removeAwsAccountInvitation = gql`
  mutation RemoveAwsAccountInvitation($input: RemoveAwsAccountInvitationInput!) {
    removeAwsAccountInvitation(input: $input) {
      success
    }
  }
`;

export default removeAwsAccountInvitation;
