import { relayStylePagination } from '@apollo/client/utilities';
import { create } from '../lib/client';
import config from '../config';
import auth from './auth';

const { api } = config;

const typePolicies = {
  Profile: {
    fields: {
      contacts: {
        merge: (existing, incoming) => incoming,
      },
      media: {
        merge: (existing, incoming) => incoming,
      },
      tradingHours: {
        merge: (existing, incoming) => incoming,
      },
      amenities: {
        merge: (existing, incoming) => incoming,
      },
      products: {
        merge: (existing, incoming) => incoming,
      },
      categories: {
        merge: (existing, incoming) => incoming,
        keyArgs: false,
      },
    },
  },
  ProfileCategory: {
    keyFields: ({ id, package: pkg }) => {
      if (pkg && typeof pkg === 'object' && '__ref' in pkg) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const pkgId = pkg['__ref'].split(':')[1];
        return `${id}-${pkgId}`;
      }
      return id;
    },
  },
  Query: {
    fields: {
      reviews: relayStylePagination(['sort', 'sortDirection', 'listingId']),
      listingPosts: relayStylePagination(['listingId', 'state']),
      enquiries: relayStylePagination(['listingId']),
      quoteListings: relayStylePagination(['listingId']),
      profileHistory: relayStylePagination(['profileId', 'startAt', 'endAt']),
    },
  },
};

const instance = create({ api, auth, typePolicies });

export default instance;
