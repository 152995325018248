import { gql } from '@apollo/client';

const pardotClaimProfile = gql`
  mutation PardotClaimProfile($data: JSONObject!) {
    pardotClaimProfile(data: $data) {
      status
    }
  }
`;

export default pardotClaimProfile;
