import { useCallback, useRef, useEffect } from 'react';
import useUser from './useUser';
import useProfile from './useProfile';
import config from '../config';

const useDataLayerPush = () => {
  const { profile } = useProfile();
  const { user, isLoading } = useUser();
  const cachedEvents = useRef([]);
  const initializeGTM = useCallback(() => {
    if (!window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${config.googleTagManager}`;
      document.head.appendChild(script);
    }
  }, []);

  const dataLayerPush = useCallback(
    (data) => {
      const pushEvent = (eventData) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventData);

        window.optimizely = window.optimizely || [];
        window.optimizely.push({
          type: 'event',
          eventName: eventData.event,
          tags: eventData,
        });
      };

      if (isLoading && !user) {
        cachedEvents.current.push(data);
        return;
      }

      const {
        id: profileId,
        suburb,
        stateCode,
        categories,
        package: packageName,
        displayName,
      } = profile || {};

      const eventData = {
        event: data.event,
        user_id: user?.id,
        login_status: user?.id ? 'authenticated' : 'unauthenticated',
        page_url: window.location.href,
        referrer: document.referrer,
        page_type: window.location.pathname,
        ...(profile && {
          business_name: displayName,
          business_category: categories?.map((category) => category.name).join(','),
          category_id: categories?.map((category) => category.id).join(','),
          package_id: packageName?.id ?? 'Free',
          package_type: packageName?.name ?? 'Free',
          profile_id: profileId,
          state: stateCode,
          suburb,
        }),
        ...data,
      };

      pushEvent(eventData);
    },
    [user, isLoading, profile],
  );

  useEffect(() => {
    if (!isLoading && user) {
      initializeGTM();

      if (!document.querySelector(`script[src="${config.optimizely}"]`)) {
        const optimizelyScript = document.createElement('script');
        optimizelyScript.src = config.optimizely;
        document.head.appendChild(optimizelyScript);
      }

      if (cachedEvents.current.length > 0) {
        cachedEvents.current.forEach((queuedEvent) => dataLayerPush(queuedEvent));
        cachedEvents.current = [];
      }
    }
  }, [isLoading, user, initializeGTM]);

  return { dataLayerPush };
};

export default useDataLayerPush;
