import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables,
} from '../../../__generated__/graphql';
import { markAllNotificationsAsRead } from '../../../graphql/mutations';

const useMarkAllNotificationsAsRead = () => {
  const [markAllAsRead, { data, ...mutationResult }] = useMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >(markAllNotificationsAsRead, {
    update(cache) {
      cache.modify({
        fields: {
          notificationCounts() {
            return {
              totalCount: 0,
              resourceTypes: [],
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    if (data?.markAllNotificationsAsRead?.readTime) {
      localStorage.setItem('notificationReadTime', data.markAllNotificationsAsRead.readTime);
    }
  }, [data?.markAllNotificationsAsRead?.readTime]);

  return {
    markAllAsRead,
    data: data?.markAllNotificationsAsRead,
    ...mutationResult,
  };
};

export default useMarkAllNotificationsAsRead;
