import { useEffect, useState } from 'react';
import { Slide } from '../Lightbox.types';
import createSlide from './createSlide';
import { Profile, ProfileMedia } from '../../../../__generated__/graphql';

function useCreateSlides(media: Profile['media']) {
  const [slides, setSlides] = useState<Slide[]>([]);

  useEffect(() => {
    const createSlides = async () => {
      setSlides(
        await media.reduce(
          async (agg, mediaItem) => {
            const mediaArray = await agg;
            const slide = await createSlide(mediaItem as ProfileMedia);
            if (!slide) {
              return mediaArray;
            }
            return [...mediaArray, slide];
          },
          Promise.resolve([]) as Promise<Slide[]>,
        ),
      );
    };

    createSlides();
  }, [media]);

  return slides;
}

export default useCreateSlides;
