import { gql } from '@apollo/client';

const setProfileSettings = gql`
  mutation setProfileSettings($input: SetProfileSettingsInput) {
    setProfileSettings(input: $input) {
      settings {
        reviewEmail
        quoteEmail
        reportEmail
        showAddress
        showReviews
        enquiryEmail
        enableEnquiryEmail
        enableQuoteEmail
      }
    }
  }
`;

export default setProfileSettings;
