import { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { setUser } from '@sentry/react';
import { user as userQuery, profile as profileQuery } from '../graphql/queries';
import { ROLES } from '../lib/constants';
import client from '../services/client';
import {
  Profile,
  UserProfileProfileRole,
  UserProfileProfileRoleEdge,
} from '../__generated__/graphql';

const { STAFF, PERSONIV } = ROLES;

interface ProfileRole extends UserProfileProfileRole {
  profile: Profile;
}
interface ProfileRoles extends UserProfileProfileRoleEdge {
  node: ProfileRole;
}

const fetchProfile = async (id: string) => {
  const res = await client.query({
    query: profileQuery,
    variables: {
      id,
    },
    errorPolicy: 'all',
  });

  return res?.data?.profile;
};

const useUser = () => {
  const {
    data: { currentUser: user } = {},
    refetch,
    startPolling,
    stopPolling,
    loading: userLoading,
  } = useQuery(userQuery, {
    errorPolicy: 'all',
  });
  const { userRoles } = user || {};
  const { edges: awsAccountRoles } = user?.awsAccountRoles || {};
  const { edges: profileRoles } = user?.profileRoles || {};

  const [validProfileRoles, setValidProfileRoles] = useState<ProfileRoles[]>();
  const [loading, setLoading] = useState(true);

  const isStaff = useMemo(() => {
    const { edges } = userRoles || {};

    if (!edges) {
      return false;
    }

    return edges.some((edge) => edge?.node?.roleName === STAFF);
  }, [userRoles]);

  const isPersoniv = useMemo(() => {
    const { edges } = userRoles || {};

    if (!edges) {
      return false;
    }

    return edges.some((edge) => edge?.node?.roleName === PERSONIV);
  }, [userRoles]);

  const validAwsAccountRoles = useMemo(
    () =>
      awsAccountRoles?.filter((role) => {
        const { node } = role || {};
        return !!node?.awsAccount;
      }),
    [awsAccountRoles],
  );

  // need to fetch profile data separately as it will hit the payload size limit if it is pulled in with the profileRoles
  useEffect(() => {
    if (!profileRoles) {
      return;
    }

    (async () => {
      const promises = profileRoles.map(async (role) => {
        const { profileId } = role?.node || {};

        const profile = await fetchProfile(profileId);

        return {
          ...role,
          node: {
            ...(role?.node || {}),
            profile,
          },
        } as ProfileRoles;
      });

      const rolesWithProfileData = await Promise.all(promises);

      setValidProfileRoles(rolesWithProfileData.filter(({ node }) => node.profile));

      setLoading(false);
    })();
  }, [profileRoles]);

  useEffect(() => {
    if (user?.account?.firstName || user?.account?.lastName || user?.account?.email) {
      setUser({
        name: `${user?.account?.firstName ? `${user.account.firstName} ` : ''}${
          user?.account?.lastName || ''
        }`,
        email: user?.account?.email,
      });
    }
  }, [user?.account?.firstName, user?.account?.lastName, user?.account?.email]);

  return {
    user: {
      ...user,
      awsAccountRoles: validAwsAccountRoles,
      profileRoles: validProfileRoles,
    },
    isStaff,
    isPersoniv,
    loading,
    userLoading,
    refetch,
    startPolling,
    stopPolling,
  };
};

export default useUser;
export type User = ReturnType<typeof useUser>;
