import { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LightboxProps } from 'yet-another-react-lightbox';

import { useDisclosure } from '../../../hooks/useDisclosure';
import { ProfileMedia } from '../../../__generated__/graphql';

function useLightbox(): LightboxValues {
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);
  const [lightboxMedia, setLightboxMedia] = useState<ProfileMedia[]>([]);
  const [options, setOptions] = useState<Partial<LightboxProps>>({});
  const { isOpen, open, close } = useDisclosure();

  const openLightbox = useCallback(
    (media: ProfileMedia[], mediaIndex = 0, lightboxOptions: Partial<LightboxProps> = {}) => {
      setLightboxMedia(media);
      setActiveMediaIndex(mediaIndex);
      open();
      setOptions(lightboxOptions);
    },
    [setLightboxMedia, setActiveMediaIndex, open],
  );

  return {
    media: lightboxMedia,
    activeMediaIndex,
    isLightboxOpen: isOpen,
    openLightbox,
    closeLightbox: close,
    options,
  };
}

interface LightboxValues {
  media: ProfileMedia[];
  activeMediaIndex: number;
  isLightboxOpen: boolean;
  openLightbox: (media: ProfileMedia[], mediaIndex?: number) => void;
  closeLightbox: () => void;
  options: Partial<LightboxProps>;
}

export default useLightbox;
export { type LightboxValues };
