import { ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from 'apollo-link-error';
import logger from '../services/logger';

const create = ({ api, auth, typePolicies }) => {
  const batchLink = new BatchHttpLink({
    uri: `${api}/graphql`,
    batchMax: 50,
  });

  const authentication = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `bearer ${auth.token()}`,
      },
    });
    return forward(operation);
  });

  const errors = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const {
          extensions: { exception },
        } = error;

        if (exception) {
          logger.warn(exception);
        }
      });
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies,
    }),
    link: from([errors, authentication, batchLink]),
  });
  return client;
};

export { create };
