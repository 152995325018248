import React, { useState } from 'react';
import { useRouteError } from 'react-router-dom';
import ErrorModal from './ErrorModal';

const ErrorBoundary: React.FC<{}> = () => {
  const error = useRouteError();
  const [hasError, setHasError] = useState(false);

  React.useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, []);

  if (hasError) {
    return <ErrorModal show />;
  }

  return null;
};

export default ErrorBoundary;
