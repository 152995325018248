import { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, matchPath } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { AWS_ACCOUNT_ROLES, PROFILE_ROLES, ROLES } from '../../../lib/constants';

const { STAFF, PERSONIV } = ROLES;
const { ADMIN, OWNER } = AWS_ACCOUNT_ROLES;
const { EDITOR } = PROFILE_ROLES;

const routeAccessRules = [
  { path: '/profile', accessRoles: [STAFF, EDITOR, OWNER, ADMIN] },
  {
    path: '/profile/:profileId',
    accessRoles: [STAFF, EDITOR, OWNER, ADMIN],
  },
  {
    path: '/profile/:profileId/posts/*',
    accessRoles: [STAFF, EDITOR, OWNER, ADMIN],
  },
  {
    path: '/profile/:profileId/reviews/*',
    accessRoles: [STAFF, EDITOR, OWNER, ADMIN],
  },
  {
    path: '/profile/:profileId/inbox/*',
    accessRoles: [STAFF, EDITOR, OWNER, ADMIN],
  },
  {
    path: '/profile/:profileId/performance/*',
    accessRoles: [STAFF, EDITOR, OWNER, ADMIN],
  },
  {
    path: '/profile/:profileId/history/*',
    accessRoles: [STAFF, PERSONIV],
  },
];

interface AwsAccountsProfileRoles {
  profileId?: string;
  profileRoleName?: string;
}

const hasRoles = (userRoles: string[] = [], roles: string[] = []) =>
  userRoles.some((roleName) => roles.some((role) => roleName.startsWith(role)));

const useAuthUser = () => {
  const { user, isStaff, isPersoniv, loading: userLoading } = useUser();
  const { awsAccountRoles } = user || {};
  const { profileRoles } = user || {};
  const { edges: userRoles } = user?.userRoles || {};

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { profileId } = useParams();
  const [isAuthorised, setIsAuthorised] = useState(false);

  const awsAccountsProfileRoles = useMemo(() => {
    if (!awsAccountRoles) {
      return undefined;
    }

    return awsAccountRoles.reduce((agg, role) => {
      const { awsAccount } = role?.node || {};
      const { edges: profiles } = awsAccount?.profiles || {};

      if (profiles?.length) {
        const awsAccountProfileRoles = profiles.map((profile) => ({
          profileId: profile?.node?.id,
          profileRoleName: EDITOR,
        }));
        return [...agg, ...awsAccountProfileRoles];
      }

      return agg;
    }, [] as AwsAccountsProfileRoles[]);
  }, [awsAccountRoles]);

  const allAccessRoles = useMemo(() => {
    const awsAccountsProfileRoleNames =
      awsAccountsProfileRoles?.map(({ profileRoleName }) => profileRoleName) || [];

    const profileRoleNames = profileRoles?.map((role) => role?.node?.profileRoleName) || [];

    const userRoleNames = userRoles?.map((role) => role?.node?.roleName) || [];

    return [...profileRoleNames, ...userRoleNames, ...awsAccountsProfileRoleNames];
  }, [userRoles, awsAccountsProfileRoles, profileRoles]);

  useEffect(() => {
    if (userLoading) {
      return;
    }

    const authoriseUser = async () => {
      if (isStaff || isPersoniv) {
        setIsAuthorised(true);
        setLoading(false);
        return;
      }

      const userProfileRoles = profileRoles?.map((role) => role?.node) || [];

      const allProfileRoles = [...(awsAccountsProfileRoles || []), ...userProfileRoles];

      const hasProfileAccess = !!allProfileRoles?.length;

      if (hasProfileAccess) {
        const authorised = allProfileRoles.some((role) => role?.profileId === profileId);
        if (authorised) {
          setIsAuthorised(true);
        } else {
          const [first] = allProfileRoles;
          if (first?.profileId) {
            navigate(`/profile/${first.profileId}`);
          }
        }
      }

      setLoading(false);
    };

    authoriseUser();
  }, [user, userLoading, profileRoles, awsAccountsProfileRoles, profileId, isStaff, isPersoniv]);

  const hasRouteAccess = useCallback(
    (route: string) => {
      const { accessRoles } = routeAccessRules.find(({ path }) => matchPath(path, route)) || {};

      if (!accessRoles) {
        return true;
      }

      return hasRoles(allAccessRoles, accessRoles);
    },
    [allAccessRoles],
  );

  return {
    loading,
    isAuthorised,
    hasRouteAccess,
  };
};

export default useAuthUser;
