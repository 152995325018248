import { createContext, PropsWithChildren, useContext } from 'react';
import useNotificationsValues, { NotificationsValues } from './hooks';

const NotificationsContext = createContext<NotificationsValues | Record<string, never>>({});

const NotificationsContextProvider = ({ children }: PropsWithChildren) => {
  const value = useNotificationsValues();

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

/**
 * Provides notifications context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   notifications,
 *   markNotificationsAsRead,
 *   markAllNotificationsAsRead,
 * } = useNotificationsContext();
 */
function useNotificationsContext() {
  const context = useContext(NotificationsContext);

  if (context === undefined) {
    throw new Error(
      'useNotificationsContext must be used within a NotificationsContextProvider Provider',
    );
  }

  return context;
}

export default useNotificationsContext;
export { NotificationsContextProvider, NotificationsContext };
