import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import {
  notifications as notificationsQuery,
  notificationCounts as notificationCountsQuery,
} from '../../../graphql/queries';
import {
  NotificationConnection,
  NotificationCountsQuery,
  NotificationsQuery,
  NotificationsQueryVariables,
} from '../../../__generated__/graphql';
import useUser from '../../../hooks/useUser';
import useProfile from '../../../hooks/useProfile';
import useAwsAccount from '../../../hooks/useAwsAccount';

const useNotifications = () => {
  const { isPersoniv, isStaff, user } = useUser();
  const { profile } = useProfile();
  const { awsAccount } = useAwsAccount();
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [win, setWin] = useState<Window | null>(null);
  const [loading, setLoading] = useState(false);

  const [notifications, setNotifications] = useState<NotificationConnection>();
  const [unreadNotifications, setUnreadNotifications] = useState<
    Record<'review' | 'enquiry' | 'quote', boolean>
  >({
    review: false,
    enquiry: false,
    quote: false,
  });
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  useEffect(() => {
    setWin(window);
  }, []);
  const location = win?.location || '';

  const profiles = useMemo(() => {
    if (isStaff || isPersoniv) {
      if (profile) {
        return [profile];
      }
      if (awsAccount?.profiles?.edges?.length) {
        return awsAccount.profiles.edges.map((edge) => edge?.node);
      }
      return [];
    }

    const awsRoles =
      user?.awsAccountRoles?.reduce?.(
        (agg, awsRole) => [
          ...agg,
          ...(awsRole?.node?.awsAccount?.profiles?.edges?.map?.(
            (profileRole) => profileRole?.node,
          ) || []),
        ],
        [] as any[],
      ) || [];
    const profileRoles =
      user?.profileRoles?.map?.((profileRole) => profileRole?.node?.profile) || [];
    return [...awsRoles, ...profileRoles];
  }, [profile, awsAccount, user?.awsAccountRoles, user?.profileRoles]);

  const { viewAllProfiles, allProfileIds, viewNoProfiles } = useMemo(() => {
    const viewAllReduced = profiles.reduce<{
      viewAllProfiles: Record<string, boolean>;
      allProfileIds: string[];
    }>(
      (agg, next) =>
        next?.id ?
          {
            viewAllProfiles: { ...agg.viewAllProfiles, [next.id]: true },
            allProfileIds: [...agg.allProfileIds, next.id],
          }
        : agg,
      { viewAllProfiles: {}, allProfileIds: [] },
    );

    const viewNoneReduced = profiles.reduce<Record<string, boolean>>(
      (agg, next) => (next ? { ...agg, [next.id]: false } : agg),
      {},
    );

    return { ...viewAllReduced, viewNoProfiles: viewNoneReduced };
  }, [profiles]);

  const [filteredProfiles, setFilteredProfiles] = useState(viewAllProfiles);
  const [filteredProfilesLength, setFilteredProfilesLength] = useState(allProfileIds?.length);
  const [profileIds, setProfileIds] = useState<string[]>(() =>
    profiles.reduce((agg, next) => (next ? [...agg, next?.id] : agg), [] as string[]),
  );

  const [fetchNotifications, { data, error, refetch, fetchMore }] = useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(notificationsQuery, {
    variables: {
      first: 5,
      consumerType: 'profile',
      consumerIds: allProfileIds,
    },
  });

  const [
    fetchNotificationCounts,
    {
      data: notificationCountsData,
      error: notificationsCountError,
      loading: notificationCountsLoading,
      refetch: refetchNotificationCounts,
    },
  ] = useLazyQuery<NotificationCountsQuery, NotificationsQueryVariables>(notificationCountsQuery, {
    variables: {
      consumerIds: allProfileIds,
    },
  });

  const [, { data: profileNotificationCountsData, refetch: refetchProfileNotificationCounts }] =
    useLazyQuery<NotificationCountsQuery, NotificationsQueryVariables>(notificationCountsQuery, {
      variables: {
        /**
         * I don't know why this is needed but if its removed then the refetch query runs twice, the second
         * time with empty variables (which returns notifications for all profiles under the account, rather
         * than just the current page's profile ID)
         */
        consumerIds: [profile?.id || 'INVALID_ID'],
      },
    });

  useEffect(() => {
    const lastReadTime = localStorage.getItem('notificationReadTime');
    const notMarkedAllAsRead =
      !lastReadTime ||
      !notifications?.edges?.[0]?.node?.createdAt ||
      new Date(notifications.edges[0].node.createdAt) > new Date(lastReadTime);
    setHasUnreadNotifications(
      !isStaff &&
        !isPersoniv &&
        !!notificationCountsData?.notificationCounts?.totalCount &&
        notMarkedAllAsRead,
    );
  }, [notificationCountsData, isStaff, isPersoniv, notifications]);

  // Initial setup
  useEffect(() => {
    setProfileIds(allProfileIds);
    setFilteredProfilesLength(allProfileIds.length);
    (async () =>
      Promise.all([
        fetchNotifications({
          variables: {
            first: 5,
            consumerType: 'profile',
            consumerIds: allProfileIds,
          },
        }),
        fetchNotificationCounts({
          variables: {
            consumerIds: allProfileIds,
          },
        }),
      ]))();
  }, [allProfileIds]);

  useEffect(() => {
    setFilteredProfiles(viewAllProfiles);
  }, [viewAllProfiles]);

  useEffect(() => {
    (async () => {
      const timeout = setTimeout(() => setLoading(true), 500);
      if (profileIds?.length) {
        await Promise.all([
          refetch({
            consumerIds: profileIds,
          }),
          ...(!isStaff && !isPersoniv ?
            [
              refetchNotificationCounts({
                consumerIds: profileIds,
              }),
            ]
          : []),
        ]);
      }

      clearTimeout(timeout);
      setLoading(false);
    })();
  }, [profileIds, location]);

  useEffect(() => {
    if (profile?.id) {
      refetchProfileNotificationCounts({
        consumerIds: [profile.id],
      });
    }
  }, [profile?.id]);

  useEffect(() => {
    setUnreadNotifications({ review: false, enquiry: false, quote: false });
    (profileNotificationCountsData?.notificationCounts?.resourceTypes || []).forEach((type) => {
      const { key, value } = type || {};
      // Normalize the key to handle 'message' as 'enquiry'
      const normalizedKey = key === 'message' ? 'enquiry' : key;
      if (
        (normalizedKey !== 'review' && normalizedKey !== 'enquiry' && normalizedKey !== 'quote') ||
        !value
      ) {
        return;
      }
      setUnreadNotifications((prev) => ({
        ...prev,
        [normalizedKey]: prev[normalizedKey] || value > 0,
      }));
    });
  }, [profileNotificationCountsData]);

  useEffect(() => {
    if (data) {
      setNotifications(data.notifications as NotificationConnection);
    } else if (!profileIds?.length) {
      setNotifications({
        edges: [],
        pageInfo: { hasPreviousPage: false, hasNextPage: false },
      });
    }
  }, [data, profileIds]);

  return {
    profiles,
    profileIds,
    hasUnreadNotifications,
    setProfileIds,
    showFiltersDialog,
    setShowFiltersDialog,
    filteredProfiles,
    setFilteredProfiles,
    filteredProfilesLength,
    setFilteredProfilesLength,
    viewAllProfiles,
    allProfileIds,
    viewNoProfiles,
    notifications,
    loading,
    error,
    fetchNotifications,
    refetchNotifications: refetch,
    fetchMoreNotifications: fetchMore,
    fetchNotificationCounts,
    refetchNotificationCounts,
    notificationCounts: notificationCountsData?.notificationCounts,
    notificationCountsLoading,
    notificationsCountError,
    unreadNotifications,
  };
};

export default useNotifications;
