import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Anchor from 'ls-common-client/src/components/Anchor';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import styled, { css } from 'styled-components';

const focusVisibleStyles = css`
  &:focus-visible {
    border: 2px solid ${(props) => props.focusBorder};
    ${(props) => props.$isPrimary && `outline: 2px solid ${props.focusOutline};`}
  }
`;

const StyledButton = styled(EmptyButton)`
  ${focusVisibleStyles}
`;

const StyledAnchor = styled(Anchor)`
  ${focusVisibleStyles}
`;

const StyledAnchorLink = styled(AnchorLink)`
  ${focusVisibleStyles}
`;

const defaultStyles = {
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: '600',
  height: '44px',
  borderRadius: '30px',
  padding: '0 20px',
  display: 'flex',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  justifyContent: 'center',
};

const buttonStyles = {
  primary: {
    background: '#285AFF',
    color: 'white',
    _disabled: {
      background: '#D9D9D9 !important',
    },
    _hover: {
      background: 'linear-gradient(180deg, rgba(152,176,253,1) 0%, rgba(45,96,251,1) 100%)',
    },
    focusBorder: '#FFFFFF',
    focusOutline: '#285AFF',
    $isPrimary: true,
  },
  secondary: {
    background: '#F8F8F8',
    color: '#285AFF',
    _disabled: {
      background: '#D9D9D9 !important',
    },
    _hover: {
      background: '#ECECEC',
    },
    focusBorder: '#285AFF',
  },
  tertiary: {
    background: 'white',
    color: '#285AFF',
    _disabled: {
      color: '#9B9B9B',
      background: 'white !important',
    },
    _hover: {
      background: '#F8F8F8',
    },
    focusBorder: '#285AFF',
  },
  quartary: {
    background: 'white',
    color: '#4A4A4A',
    _disabled: {
      color: '#9B9B9B !important',
      background: 'white !important',
    },
    _hover: {
      color: '#285AFF',
      background: '#F8F8F8',
    },
    focusBorder: '#285AFF',
  },
};

const tags = {
  button: ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>,
  a: ({ children, ...props }) => <StyledAnchor {...props}>{children}</StyledAnchor>,
  aLink: ({ children, ...props }) => <StyledAnchorLink {...props}>{children}</StyledAnchorLink>,
};

const Button = ({ buttonStyle = 'primary', tagType = 'button', ...props }) => {
  const button = tags[tagType];
  const styles = buttonStyles[buttonStyle];

  return button({ ...defaultStyles, ...styles, ...props });
};

Button.propTypes = {
  buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quartary']),
  tagType: PropTypes.oneOf(['a', 'aLink', 'button']),
};

Button.defaultProps = {
  buttonStyle: 'primary',
  tagType: 'button',
};

export default Button;
