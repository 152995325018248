import moment from 'moment';
import dayjs, { ConfigType } from 'dayjs';
import getVideoId from 'get-video-id';
import { formatGeneric } from 'ls-common-client/src/phone';
import { ApolloError } from '@apollo/client/errors';
import Joi from 'joi';
import { LISTING_POST_STATES, CONTACT_TYPES } from './constants';
import config from '../config';
import { Edges, EdgesWithPageInfo, ProfileDTO } from '../types';
import { User } from '../types/user';
import useUser from '../hooks/useUser';

const { lsOnlineUrlRoot } = config;

const { DISABLED } = LISTING_POST_STATES;
const { PHONE } = CONTACT_TYPES;

const addressJoin = (
  street: string | null | undefined,
  suburb: string | null | undefined,
  state: string | null | undefined,
  postCode: string | null | undefined,
) => {
  const rest = [suburb, state, postCode];
  const st = street ? `${street},` : '';
  return [st, ...rest].filter(Boolean).join(' ');
};

const listingPostDisabled = ({ state, startDate, endDate }: any) => {
  const now = moment().startOf('day');
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');

  return (state === DISABLED && !startDate && !endDate) || start > now || end < now;
};

const sortCategories = (categories: any[]) => {
  const packageCategories = categories.filter((category) => category.package?.id);
  const otherCategories = categories.filter((category) => !category.package?.id);

  const sortedPackageCategories = packageCategories.sort((a, b) => {
    const { sort: sortA = 0 } = a.package || {};
    const { sort: sortB = 0 } = b.package || {};
    return sortA - sortB;
  });

  return [...sortedPackageCategories, ...otherCategories];
};

function sortData<D extends Record<string, any>>(
  data: D[],
  labelOrder = ['mobile', 'desktop', 'tablet'],
  { labelKey, valueKey } = { labelKey: 'metric', valueKey: 'value' },
) {
  if (!data?.length) {
    return [];
  }
  const sortedData = data
    .map((item) => ({
      ...item,
      totalForSorting:
        Array.isArray(item[valueKey]) ?
          item[valueKey]?.reduce((acc: number, val: number) => acc + val, 0)
        : item[valueKey],
    }))
    .sort((a, b) => b.totalForSorting - a.totalForSorting);
  if (labelOrder?.length) {
    sortedData.sort((a, b) => {
      const aIndex = labelOrder.findIndex(
        (label) => label.toLowerCase() === a[labelKey]?.toLowerCase(),
      );
      const bIndex = labelOrder.findIndex(
        (label) => label.toLowerCase() === b[labelKey]?.toLowerCase(),
      );

      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      return 0;
    });
  }
  return sortedData.map(({ totalForSorting, ...rest }) => rest);
}

const createProfileUrl = (vanityUrlPath: string) => `${lsOnlineUrlRoot}/profile/${vanityUrlPath}`;

const getUpgradeLink = (profile?: ProfileDTO | null) => {
  if (!profile) {
    return '';
  }

  const { contacts, street, suburb, stateCode, postcode, displayName, vanityUrlPath } = profile;

  const address = addressJoin(street, suburb, stateCode, postcode);
  const { value: phone } = contacts.find(({ type }) => type === PHONE) || {};

  const profileInfo = [
    { label: 'Business Name', value: displayName },
    { label: 'Address', value: address },
    { label: 'Phone', value: formatGeneric(phone) },
    { label: 'Profile URL', value: createProfileUrl(vanityUrlPath) },
  ];

  const profileText = profileInfo.map(({ label, value }) => `${label}: ${value}`).join('%0D%0A');

  return `mailto:leads@localsearch.com.au?subject=Upgrade My Business Profile&body=I would like to upgrade my Localsearch business profile.%0D%0A%0D%0A${profileText}`;
};

type PlacesAddressType =
  | 'subpremise'
  | 'street_number'
  | 'route'
  | 'locality'
  | 'administrative_area_level_1'
  | 'postal_code';

const getAddressParts = (addressComponents: any[]) => {
  const placesAddressMap: Record<PlacesAddressType, { map: string; key: string }> = {
    subpremise: { map: 'street', key: 'long_name' },
    street_number: { map: 'street', key: 'long_name' },
    route: { map: 'street', key: 'long_name' },
    locality: { map: 'suburb', key: 'long_name' },
    administrative_area_level_1: { map: 'stateCode', key: 'short_name' },
    postal_code: { map: 'postcode', key: 'long_name' },
  };

  let parts: { [k: string]: string } = {};

  addressComponents.forEach((component) => {
    const { types } = component as {
      long_name: string;
      short_name: string;
      types: PlacesAddressType[];
    };
    const matchingType = types.find((type) => placesAddressMap[type]);
    if (!matchingType) {
      return;
    }
    const { map, key } = placesAddressMap[matchingType];

    if (parts[map]) {
      parts = {
        ...parts,
        [map]: `${parts[map]} ${component[key]}`,
      };
    } else {
      parts = {
        ...parts,
        [map]: component[key],
      };
    }
  });

  return parts;
};

const camelToSpace = (camel: string) =>
  camel.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

const getVimeoThumb = async (id: string | number) => {
  const res = await fetch(`https://vimeo.com/api/v2/video/${id}.json`);
  const [{ thumbnail_large: thumbnailLarge }] = await res.json();
  return thumbnailLarge;
};

const getYouTubeThumb = (id: string | number) => `https://img.youtube.com/vi/${id}/0.jpg`;

const getVideoUrl = (originalUrl: string) => {
  const { id, service } = getVideoId(originalUrl);

  if (!id) {
    return originalUrl;
  }

  let url;

  if (service === 'youtube') {
    url = `https://www.youtube.com/watch?v=${id}`;
  }

  if (service === 'vimeo') {
    url = `https://vimeo.com/${id}`;
  }

  return url;
};

const getVideoImageUrl = async (url: string) => {
  const { id, service } = getVideoId(url);

  let thumbnail;

  if (service === 'youtube') {
    thumbnail = getYouTubeThumb(id || '');
  }

  if (service === 'vimeo') {
    thumbnail = await getVimeoThumb(id || '');
  }

  return thumbnail;
};

const hasObjectChanged = (
  compare: { [k: string]: unknown } = {},
  compareTo: { [k: string]: unknown } = {},
) => Object.keys(compare).some((key) => compare[key] !== compareTo[key]);

const difference = (compare = [], compareTo = []) =>
  compare.filter((obj, i) => hasObjectChanged(obj, compareTo[i]));

const renderValue = (value: unknown) => {
  let string = value;

  if (Array.isArray(value) && !value.length) {
    string = '';
  }

  if (value === null) {
    string = '';
  }

  if (typeof value === 'boolean') {
    string = value ? 'true' : 'false';
  }

  return string;
};

const mapEdgesToNodeItems = <T = unknown>(data?: Edges<T> | EdgesWithPageInfo<T>) =>
  data?.edges?.map(({ node }) => node);

const omit = (obj: { [key: string]: any }, ...keys: string[]) => {
  if (!obj) {
    return obj;
  }
  const omitKeys = keys.length && Array.isArray(keys[0]) ? keys[0] : keys;

  return Object.keys(obj).reduce(
    (aggregate, key) =>
      omitKeys.includes(key) ? aggregate : (
        {
          ...aggregate,
          [key]: obj[key],
        }
      ),
    {},
  );
};

const youtubeRegex =
  /^https?:\/\/(?:(?:youtu\.be\/)|(?:(?:www\.)?youtube\.com\/(?:(?:watch\?(?:[^&]+&)?vi?=)|(?:vi?\/)|(?:shorts\/))))([a-zA-Z0-9_-]{11,})/;
const vimeoRegex = /vimeo.*\/(\d+)/;

/**
 * Get the ID of a video on YouTube or Vimeo by its URL
 * @param url URL to the video
 * @param type 'youtube' or 'vimeo'
 * @returns The ID for the video
 */
function getVideoID(url: string, type: 'youtube' | 'vimeo' | 'youTube') {
  const match = url.match(type === 'youtube' || type === 'youTube' ? youtubeRegex : vimeoRegex);
  return match?.[1] || null;
}
const userInfo = (
  userObj?: User['account'],
): {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  fullName: string;
} => {
  const { email, firstName, lastName, phoneNumber } = userObj || {};
  const emailId: string = email || '';
  const fName: string = firstName || '';
  const lName: string = lastName || '';
  const phoneNum: string = phoneNumber || '';

  const fullName = [fName, lName].filter(Boolean).join(' ');
  return {
    email: emailId,
    firstName: fName,
    lastName: lName,
    phoneNumber: phoneNum,
    fullName,
  };
};

function abbreviateNumber(num: number): string {
  if (Math.abs(num) >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}M`;
  }
  if (Math.abs(num) >= 1_000) {
    return `${(num / 1_000).toFixed(1)}K`;
  }
  return num.toString();
}

function isValidVideoUrl(url: string) {
  const schema = Joi.string().uri();
  const { error } = schema.validate(url);
  if (error) {
    return false;
  }

  const { id, service } = getVideoId(url);
  return id && ['youtube', 'vimeo'].includes(service || '');
}

function isInvalidURI(error: ApolloError) {
  const { graphQLErrors } = error;
  const isInvalidUriError = graphQLErrors.some((err) =>
    err?.extensions?.['exception']?.errors?.some((e: { type: string }) => e.type === 'string.uri'),
  );
  return isInvalidUriError;
}
function getUserTypeForDataLayer(userAcc: ReturnType<typeof useUser>) {
  if (userAcc.isPersoniv) {
    return 'LS Partners - Personiv';
  }

  if (userAcc.isStaff) {
    return 'LS Employee';
  }

  return !!userAcc?.user?.profileRoles?.length || !!userAcc?.user?.awsAccountRoles?.length ?
      'Client'
    : 'User';
}

const timeElapsed = (date: ConfigType) => {
  const now = dayjs();
  const inputDate = dayjs(date);

  // Calculate the difference between the two dates in milliseconds
  const diff = now.valueOf() - inputDate.valueOf();

  // If the difference is less than a minute, return "just now"
  if (diff < 60 * 1000) {
    return 'just now';
  }

  // Define the time intervals and their respective units
  const intervals = [
    { unit: 'year', seconds: 31536000 },
    { unit: 'month', seconds: 2592000 },
    { unit: 'week', seconds: 604800 },
    { unit: 'day', seconds: 86400 },
    { unit: 'hour', seconds: 3600 },
    { unit: 'minute', seconds: 60 },
  ];

  // Iterate over the intervals and find the largest one that fits
  for (let i = 0; i < intervals.length; i += 1) {
    const interval = intervals[i] as { unit: string; seconds: number };
    const intervalDiff = Math.floor(diff / (interval.seconds * 1000));
    if (intervalDiff >= 1) {
      const unit = intervalDiff === 1 ? interval.unit : `${interval.unit}s`;
      return `${intervalDiff} ${unit} ago`;
    }
  }

  // If none of the intervals fit, return "just now"
  return 'just now';
};

interface ArrayItem {
  id: string | number;
}

const isModified = (originalArr: ArrayItem[], modifiedArr: ArrayItem[]): boolean => {
  const originalIds = new Set(originalArr.map((item) => item.id));
  const modifiedIds = modifiedArr.map((item) => item.id);

  if (originalIds.size !== modifiedIds.length) {
    return true;
  }

  return modifiedIds.some((id) => !originalIds.has(id));
};

export {
  addressJoin,
  listingPostDisabled,
  sortCategories,
  sortData,
  getUpgradeLink,
  createProfileUrl,
  getAddressParts,
  camelToSpace,
  getVideoImageUrl,
  getVideoUrl,
  hasObjectChanged,
  difference,
  renderValue,
  mapEdgesToNodeItems,
  omit,
  getVideoID,
  userInfo,
  abbreviateNumber,
  isValidVideoUrl,
  isInvalidURI,
  timeElapsed,
  getUserTypeForDataLayer,
  isModified,
};
