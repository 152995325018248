import { useState } from 'react';
import cuid from 'cuid';

const useNotificationSlider = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const onClose = (key: string) => {
    setNotifications((current) =>
      current.map((props) => (key === props.key ? { ...props, show: false } : props)),
    );
  };

  const onHasClosed = (key: string) => {
    setNotifications((current) => current.filter((props) => key !== props.key));
  };

  const open = (props: Record<string, any>) => {
    setNotifications((current) => {
      const key = cuid();
      return [
        {
          show: true,
          key,
          onClose: () => onClose(key),
          onHasClosed: () => onHasClosed(key),
          ...props,
        },
        ...current,
      ];
    });
  };

  return {
    open,
    notifications,
  };
};

type Notification = {
  [key: string]: any;
  key: string;
  show: boolean;
  onClose: () => void;
  onHasClosed: () => void;
};

export default useNotificationSlider;
