import { gql } from '@apollo/client';

const removeProfileInvitation = gql`
  mutation RemoveProfileInvitation($input: RemoveProfileInvitationInput!) {
    removeProfileInvitation(input: $input) {
      success
    }
  }
`;

export default removeProfileInvitation;
