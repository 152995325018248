import { useCallback, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { CtaPosition } from '../../../types';
import { ProfileCategory, ProfilePackage } from '../../../__generated__/graphql';
import { userInfo } from '../../../lib/util';

type Nullable<DataType> = DataType | null;

interface Profile {
  id: string;
  street?: string | null;
  displayName: string;
  stateCode: string;
  suburb: string;
  categories: Nullable<ProfileCategory>[];
  package?: ProfilePackage | null;
}

const useContactUsDialog = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [showDialog, setShowDialog] = useState(queryParams.has('contact-us'));
  const [profileId, setProfileId] = useState('');
  const [companyName, setCompanyName] = useState<string | undefined>('');
  const [address, setAddress] = useState<string | null | undefined>('');
  const [categoryNames, setCategoryNames] = useState<Nullable<ProfileCategory>[]>([]);
  const [ctaPosition, setCtaPosition] = useState('');
  const [suburbName, setSuburb] = useState('');
  const [state, setState] = useState('');
  const [packageInfo, setPackageInfo] = useState<ProfilePackage>({
    id: 'Free',
    name: 'Free',
    sort: 0,
  });
  const { user } = useUser();
  const { email, firstName, phoneNumber } = userInfo(user.account || {});

  const open = useCallback(
    ({ profile }: { profile?: Profile } = {}, cta_position?: CtaPosition) => {
      if (!profile) {
        setShowDialog(true);
        return;
      }
      const {
        id,
        street,
        displayName,
        stateCode,
        suburb,
        categories,
        package: profilePackage,
      } = profile || {};

      setProfileId(id);
      setAddress(street);
      setCompanyName(displayName);
      setShowDialog(true);
      setCategoryNames(categories);
      setCtaPosition(cta_position || '');
      setState(stateCode);
      setSuburb(suburb);
      const newPackageInfo = profilePackage || {
        id: 'Free',
        name: 'Free',
        sort: 0,
      };
      setPackageInfo(newPackageInfo);
    },
    [showDialog],
  );

  const close = useCallback(() => {
    setShowDialog(false);
  }, [showDialog]);

  const defaultValues = {
    message: '',
    email,
    phone: phoneNumber,
    firstName,
  };

  return {
    showDialog,
    defaultValues,
    open,
    close,
    profileId,
    state,
    suburbName,
    companyName,
    address,
    categoryNames,
    ctaPosition,
    packageInfo,
  };
};

export default useContactUsDialog;
