import { PropsWithChildren, createContext, useContext, useRef } from 'react';
import ContactUsFormDialog from 'ls-common-client/src/components/ContactUsFormDialog';
import Container from 'ls-common-client/src/components/Container';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import useGlobalContextValues, { GlobalContextValue } from './hooks';
import { TRACKING } from '../../lib/constants';
import useDataLayerPush from '../../hooks/useDataLayerPush';

const Context = createContext<GlobalContextValue | Record<string, never>>({});

const Provider = ({ children }: PropsWithChildren) => {
  const value = useGlobalContextValues();
  const {
    contactUsDialog: {
      defaultValues,
      showDialog,
      close,
      profileId,
      state,
      ctaPosition,
      suburbName,
      packageInfo,
      categoryNames,
      companyName,
      address,
    },

    media: { mobile },
  } = value;
  const { dataLayerPush } = useDataLayerPush();
  const portalRef = useRef();
  const dataLayerValues = {
    event: TRACKING.EVENTS.FORM_SUBMIT,
    page_type: window.location.pathname,
    form_type: TRACKING.FORM_TYPES.UPGRADE,
    cta_text: 'Send',
    cta_position_on_page: ctaPosition,
    business_category: categoryNames?.map((category) => category?.name).join(','),
    category_id: categoryNames?.map((category) => category?.id).join(','),
    package_id: packageInfo?.id,
    index: 'Step-2',
    profile_id: profileId,
    state,
    suburb: suburbName,
    business_name: companyName,
  };

  return (
    <Context.Provider value={value}>
      {children}
      <ContactUsFormDialog
        show={showDialog}
        onSubmitSuccess={() => {
          dataLayerPush(dataLayerValues);
          close();
        }}
        onClose={() => close()}
        mode={mobile ? 'mobile' : 'desktop'}
        profileId={profileId}
        companyName={companyName}
        address={address}
        defaultValues={defaultValues}
        form_type={TRACKING.FORM_TYPES.CONTACT_US}
      />

      {value.notificationSlider.notifications.map((props) => (
        <NotificationSlider
          mode={value.media.mobile ? 'mobile' : 'desktop'}
          zIndex="10"
          portalElement={portalRef.current}
          position="relative"
          width={value.media.mobile ? '100%' : '465px'}
          {...props}
        />
      ))}
      <Container
        ref={portalRef}
        position="fixed"
        zIndex="101"
        bottom="0"
        left="0"
        right="0"
        width={value.media.mobile ? undefined : '30%'}
      />
    </Context.Provider>
  );
};

/**
 * Provides global context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   theme,
 *   media,
 *   notifications,
 *   accountProfileSearch,
 *   sideBar,
 * } = useGlobalContext();
 */
function useGlobalContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContext Provider');
  }

  return context;
}

export default useGlobalContext;
export { Provider, Context };
