import { CSSProperties } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import { PlanObj } from '../../../../types/upgradePlan';

interface PlanProps extends CSSProperties {
  plan: PlanObj;
  index: number;
  isActive: boolean;
  onClick: () => void;
}

const Plan = ({ plan, index, isActive, onClick, ...props }: PlanProps) => (
  <Container marginLeft={index !== 0 && '20px'} onClick={onClick} {...props}>
    <Container
      display="flex"
      justifyContent="start"
      alignItems="left"
      flexDirection="column"
      padding="30px 20px 30px 20px"
      width="252px"
      backgroundColor="#F8F8F8"
      border={isActive ? '3px solid #285aff' : '3px solid transparent'}
      borderRadius="20px"
      height="390px"
      _hover={{
        backgroundColor: '#ECECEC',
      }}
      cursor="pointer"
    >
      <Container display="flex" justifyContent="start" alignItems="left" height="70px">
        <Image src={plan.image} />
      </Container>
      <Container margin="15px 0">
        <Text color="#4a4a4a" fontSize="18px" fontWeight="700">
          {plan.name}
        </Text>
      </Container>
      <Container display="flex" justifyContent="start" flexDirection="column" marginBottom="15px">
        <Text color="#4a4a4a" fontSize="12px" fontWeight="450">
          Starting from
        </Text>
        <Text color="#4a4a4a" fontSize="45px" fontWeight="700">
          ${plan.price}
          <Text color="#4a4a4a" fontSize="18px" fontWeight="450">
            /mon
          </Text>
        </Text>
      </Container>

      <Container>
        {plan?.features.map((feature) => (
          <Container display="flex" justifyContent="start" alignItems="center" marginBottom="13px">
            <Icon
              className="lsDesignIcon-check-bold"
              fontSize="20px"
              color="#285AFF"
              marginRight="5px"
            />
            <Text color="#4a4a4a" fontSize="14px" fontWeight="450">
              {feature}
            </Text>
          </Container>
        ))}
      </Container>
    </Container>
  </Container>
);

export default Plan;
