import { ReactNode } from 'react';
import { Slide } from '../Lightbox.types';
import { Profile } from '../../../../__generated__/graphql';
import { getVideoID, getVideoImageUrl } from '../../../../lib/util';
import imageResizer from '../../../../services/imageResizer';

const MAX_SIZE = {
  height: 720,
  width: 1280,
};

const THUMBNAIL_SIZE = {
  height: 80,
  width: 120,
};

async function createSlide(
  mediaItem: Profile['media'][number] & { lightboxContent?: ReactNode },
): Promise<Slide | null> {
  switch (mediaItem?.type) {
    case 'document': {
      return {
        type: 'document',
        src: imageResizer.resize(mediaItem.url, {
          width: '620px',
          height: '620px',
        }),
        thumbnail: imageResizer.resize(mediaItem.url, {
          height: THUMBNAIL_SIZE.height,
          width: THUMBNAIL_SIZE.width,
        }),
        title: mediaItem.description,
        description: mediaItem.lightboxContent,
        downloadUrl: mediaItem.url,
        height: MAX_SIZE.height,
        width: MAX_SIZE.width,
      };
    }

    case 'image': {
      return {
        type: 'image',
        src: imageResizer.resize(mediaItem.url, {
          height: MAX_SIZE.height,
          width: MAX_SIZE.width,
        }),
        thumbnail: imageResizer.resize(mediaItem.url, {
          height: THUMBNAIL_SIZE.height,
          width: THUMBNAIL_SIZE.width,
        }),
        title: mediaItem.description,
        description: mediaItem.lightboxContent,
        downloadUrl: mediaItem.url,
        height: MAX_SIZE.height,
        width: MAX_SIZE.width,
      };
    }

    case 'vimeo': {
      const id = getVideoID(mediaItem.url, 'vimeo');
      if (!id) {
        return null;
      }
      const thumbnail = await getVideoImageUrl(mediaItem.url);
      return {
        type: 'vimeo',
        src: `https://player.vimeo.com/video/${id}`,
        thumbnail: thumbnail || undefined,
        title: mediaItem.description || 'Vimeo video player',
        description: mediaItem.lightboxContent,
        height: MAX_SIZE.height,
        width: MAX_SIZE.width,
      };
    }
    case 'youTube': {
      const id = getVideoID(mediaItem.url, 'youtube');
      if (!id) {
        return null;
      }
      return {
        type: 'youTube',
        src: `https://www.youtube.com/embed/${id}`,
        thumbnail: `https://img.youtube.com/vi/${id}/hqdefault.jpg`,
        title: mediaItem.description || 'Youtube video player',
        description: mediaItem.lightboxContent,
        height: MAX_SIZE.height,
        width: MAX_SIZE.width,
      };
    }
    default: {
      return null;
    }
  }
}

export default createSlide;
