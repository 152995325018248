import React from 'react';
import PropTypes from 'prop-types';
import CommonAlertDialog from 'ls-common-client/src/components/AlertDialog';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';

const AlertDialog = ({ heading, text, image, ...props }) => (
  <CommonAlertDialog
    padding="30px 30px"
    heading={
      <Container textAlign="center">
        <Text fontSize="25px" fontWeight="700">
          {heading}
        </Text>
      </Container>
    }
    text={
      <Text fontSize="16px" color="757575" fontWeight="500" textAlign="center" display="block">
        {text}
      </Text>
    }
    {...props}
  />
);

AlertDialog.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.bool,
};

AlertDialog.defaultProps = {
  heading: null,
  text: null,
  image: true,
};

export default AlertDialog;
