import { gql } from '@apollo/client';

const claimProfile = gql`
  mutation claimProfile($input: ClaimProfileInput!) {
    claimProfile(input: $input) {
      token
      expiresAt
    }
  }
`;

export default claimProfile;
