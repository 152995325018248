import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Navigate,
  useParams,
} from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  showReportDialog,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { Provider as ManageAccountProvider } from '../../context/ManageAccountContext';
import { Provider as ManageProfileProvider } from '../../context/ManageProfileContext';
import { Provider as CreateProfileProvider } from '../../context/CreateProfileContext';
import { Provider as ClaimProfileProvider } from '../../context/ClaimProfileContext';
import accountRoutes from './account';
import profileRoutes from './profile';
import config from '../../config';
import SentryErrorWrapper from './SentryErrorWrapper';
import SentryRouteErrorFallback from '../UI/molecules/SentryRouteErrorBoundary';
import { NotificationsContextProvider } from '../../context/NotificationsContext';

const CreateProfile = lazy(() => import('../pages/CreateProfile'));
const ClaimProfile = lazy(() => import('../pages/ClaimProfile'));
const AssetUploader = lazy(() => import('../pages/AssetUploader'));
const CMSBatchUploader = lazy(() => import('../pages/CMSBatchUploader'));
const ManageAccount = lazy(() => import('../templates/ManageAccount'));
const ManageProfile = lazy(() => import('../templates/ManageProfile'));

// Don't log local env errors as this just wastes our Sentry quota
if (config.env !== 'local') {
  init({
    ...config.sentry,
    beforeSend(event) {
      if (event.exception && event.event_id) {
        showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(config.sentryReplayIntegration),
    ],
  });
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const ProfileRedirect = ({ to }: { to: string }) => {
  const { profileId } = useParams();
  return <Navigate replace to={`/profile/${profileId}/${to}`} />;
};

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      element: (
        <NotificationsContextProvider>
          <SentryErrorWrapper />
        </NotificationsContextProvider>
      ),
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          path: '/',
          element: (
            <ManageAccountProvider>
              <Suspense>
                <ManageAccount />
              </Suspense>
            </ManageAccountProvider>
          ),
          children: accountRoutes,
        },
        {
          path: 'create-profile',
          element: (
            <CreateProfileProvider>
              <Suspense>
                <CreateProfile />
              </Suspense>
            </CreateProfileProvider>
          ),
        },
        {
          path: 'claim-profile/:profileId',
          element: (
            <ClaimProfileProvider>
              <Suspense>
                <ClaimProfile />
              </Suspense>
            </ClaimProfileProvider>
          ),
        },
        {
          path: 'asset-uploader',
          element: (
            <Suspense>
              <AssetUploader />
            </Suspense>
          ),
        },
        {
          path: 'cms-batch-uploader',
          element: (
            <Suspense>
              <CMSBatchUploader />
            </Suspense>
          ),
        },
        {
          path: 'profile',
          element: (
            <ManageProfileProvider>
              <Suspense>
                <ManageProfile />
              </Suspense>
            </ManageProfileProvider>
          ),
          children: profileRoutes,
        },
        {
          path: 'info/:profileId',
          element: <ProfileRedirect to="info" />,
        },
        {
          path: 'reviews/:profileId',
          element: <ProfileRedirect to="reviews" />,
        },
        {
          path: 'quotes/:profileId',
          element: <ProfileRedirect to="inbox/quotes" />,
        },
        {
          path: 'enquiries/:profileId',
          element: <ProfileRedirect to="inbox/messages" />,
        },
        {
          path: 'posts/:profileId',
          element: <ProfileRedirect to="posts/enabled" />,
        },
        {
          path: 'media/:profileId',
          element: <ProfileRedirect to="media/logo" />,
        },
        {
          path: 'insights/:profileId',
          element: <ProfileRedirect to="performance/google-ads" />,
        },
        {
          path: 'history/:profileId',
          element: <ProfileRedirect to="history" />,
        },
      ],
    },
  ],
  {
    basename: `/${config.BASE_PATH || ''}`,
  },
);

export default router;
