import { gql } from '@apollo/client';

const categorySuggest = gql`
  query categorySuggest($query: String!, $enabled: Boolean) {
    categorySuggest(query: $query, enabled: $enabled) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default categorySuggest;
