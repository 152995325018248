import { gql } from '@apollo/client';

const resendProfileInvitation = gql`
  mutation ResendProfileInvitation($input: ResendProfileInvitationInput!) {
    resendProfileInvitation(input: $input) {
      profileInvitation {
        id
        name
        email
        createdAt
        expiresAt
        profileRoles {
          profileId
          profileRoleName
          profile {
            logo
            id
          }
        }
      }
    }
  }
`;

export default resendProfileInvitation;
