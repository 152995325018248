import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@apollo/client';
import { CreateAwsAccountInvitationPayload } from '../../__generated__/graphql';

interface CreateAwsAccountInvitation {
  createAwsAccountInvitation: CreateAwsAccountInvitationPayload;
}

const createAwsAccountInvitation = gql`
  mutation createAwsAccountInvitation($input: CreateAwsAccountInvitationInput!) {
    createAwsAccountInvitation(input: $input) {
      awsAccountInvitation {
        id
        name
        email
        createdAt
        expiresAt
        awsAccountRoles {
          awsAccountId
          awsAccountRoleName
          profiles {
            edges {
              node {
                id
                logo
              }
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<CreateAwsAccountInvitation>;

export default createAwsAccountInvitation;
