import { useState, useRef } from 'react';

const useAccountProfileSearch = () => {
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const open = () => {
    setShow(true);
    inputRef?.current?.focus();
  };

  const close = () => {
    setShow(false);
  };

  return {
    show,
    open,
    close,
    inputRef,
  };
};

export default useAccountProfileSearch;
