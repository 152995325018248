import { Outlet } from 'react-router-dom';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import ErrorBoundary from '../UI/molecules/ErrorBoundary';

/**
 * Wraps the entire app in Sentry's ErrorBoundary component.
 *
 * @see https://github.com/getsentry/sentry-javascript/discussions/6912#discussioncomment-8792266
 */
const SentryErrorWrapper = () => (
  <SentryErrorBoundary fallback={<ErrorBoundary />} showDialog>
    <Outlet />
  </SentryErrorBoundary>
);

export default SentryErrorWrapper;
