/* eslint-disable import/no-unresolved */
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as theme from 'ls-common-client/src/themes/default';
import { Provider as GlobalContextProvider } from '../context/GlobalContext';
import router from './routes';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    font-family: ${(props) => props.theme.font.circular};
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.text.normal};
    height: 1px;
    min-height: 100%;
    background-color: ${(props) => props.theme.background.normal};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  button {
    font-family: Circular;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalContextProvider>
      <GlobalStyle />
      <RouterProvider router={router} />
    </GlobalContextProvider>
  </ThemeProvider>
);

export default App;
