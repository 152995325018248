import { CSSProperties, ReactNode, useContext } from 'react';
import Button from 'ls-common-client/src/components/Button';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import { Context } from '../../../../context/GlobalContext';

interface FormDialogSubmitProps extends CSSProperties {
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  loaderProps?: CSSProperties;
  onClick?: () => void;
  _hover?: CSSProperties;
}

const FormDialogSubmit = ({
  children = 'Update',
  loading = false,
  disabled = false,
  loaderProps = {},
  ...props
}: FormDialogSubmitProps) => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  return desktop ?
      <EmptyButton
        type="submit"
        color="primary"
        fontSize="16px"
        fontWeight="600"
        position="relative"
        padding="8px 15px"
        overflow="hidden"
        borderRadius="30px"
        marginRight="-8px"
        disabled={loading || disabled}
        _hover={
          !(loading || disabled) && {
            backgroundColor: 'background200',
          }
        }
        _disabled={{
          color: '#9B9B9B',
        }}
        cursor={loading || disabled ? 'not-allowed' : 'pointer'}
        {...props}
      >
        {loading && (
          <Container
            height="100%"
            width="100%"
            backgroundColor="white"
            left="0"
            top="0"
            position="absolute"
            {...loaderProps}
          >
            <Loader
              position="absolute"
              transform="translate(-50%,-50%)"
              top="50%"
              left="50%"
              width="130px"
            />
          </Container>
        )}
        {children}
      </EmptyButton>
    : <Button
        type="submit"
        rounded
        primary
        height="40px"
        width={mobile ? '100%' : 'auto'}
        loading={loading}
        disabled={loading || disabled}
        _disabled={{
          color: 'white',
          backgroundColor: 'background500',
        }}
        {...props}
      >
        {children}
      </Button>;
};

export default FormDialogSubmit;
