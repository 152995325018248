import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { Context } from '../../../../context/GlobalContext';
import MoreButton from '../../atoms/MoreButton';
import { Context as FormDialogContext } from './FormDialogContext';

const DialogHeader = ({
  header = '',
  subHeader = '',
  glossary = false,
  onClose = () => {},
  ...props
}) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const { onCloseConfirm } = useContext(FormDialogContext);

  return (
    <Container marginBottom={mobile ? '50px' : '20px'} {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        position="relative"
        alignItems="center"
        marginBottom="20px"
      >
        <Text
          fontSize={mobile ? '30px' : '25px'}
          fontWeight="600"
          lineHeight="1"
          flex="1"
          display="block"
        >
          {header}
        </Text>
        <EmptyButton
          onClick={() => {
            onCloseConfirm();
            onClose();
          }}
          marginLeft="16px"
          borderRadius="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="10px"
          height={mobile ? '40px' : undefined}
          width={mobile ? '40px' : undefined}
          boxShadow={mobile ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : undefined}
          marginTop={!mobile ? '-10px' : undefined}
          marginRight={!mobile ? '-10px' : undefined}
        >
          <Icon fontSize="20px" color="normal" className="lsDesignIcon-x-bold" />
        </EmptyButton>
      </Container>
      <Container display="flex" justifyContent="space-between">
        {subHeader && (
          <Text fontSize="16px" color="text400" lineHeight="1.2" justifyContent="space-between">
            {subHeader}
          </Text>
        )}
        {glossary && (
          <MoreButton
            forwardedAs={Anchor}
            display="inline-block"
            href="https://knowledge.localsearch.com.au/localsearch-glossary/"
            target="_blank"
            fontSize="14px"
          >
            View Localsearch Glossary
            <Icon fontSize="16px" className="lsDesignIcon-externalLink-bold" marginLeft="5px" />
          </MoreButton>
        )}
      </Container>
    </Container>
  );
};

DialogHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  glossary: PropTypes.bool,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
};

export default DialogHeader;
