import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

/**
 * TODO: Write JSDocs.
 */
const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    try {
      captureException(routeError, { level: 'fatal' });
    } catch (e) {
      captureException(JSON.stringify({ routeError, e }, null, 2), {
        level: 'fatal',
      });
    }
  }, [routeError]);

  return <ErrorBoundary />;
};

export default SentryRouteErrorFallback;
