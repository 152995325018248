import { gql } from '@apollo/client';

const notificationCounts = gql`
  query notificationCounts(
    $resourceType: String
    $consumerType: String
    $consumerIds: [String]
    $isRead: Boolean
  ) {
    notificationCounts(
      resourceType: $resourceType
      consumerType: $consumerType
      consumerIds: $consumerIds
      isRead: $isRead
    ) {
      totalCount
      resourceTypes {
        key
        value
      }
    }
  }
`;

export default notificationCounts;
