import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';

const LocalsearchPlus = lazy(() => import('../../../pages/Profile/Performance/LocalsearchPlus'));
const GoogleAds = lazy(() => import('../../../pages/Profile/Performance/GoogleAds'));
const SEO = lazy(() => import('../../../pages/Profile/Performance/SEO'));
const SocialMedia = lazy(() => import('../../../pages/Profile/Performance/SocialMedia'));
const Websites = lazy(() => import('../../../pages/Profile/Performance/Websites'));

const PerformanceBoost = lazy(() => import('../../../pages/Profile/Performance/PerformanceBoost'));

const PerformanceBoostPrint = lazy(
  () => import('../../../pages/Profile/Performance/PerformanceBoost/PerformanceBoostPrint'),
);

const LocalSearchPlusPrint = lazy(
  () => import('../../../pages/Profile/Performance/LocalsearchPlus/LocalsearchPlusPrint'),
);

const GoogleAdsPrint = lazy(
  () => import('../../../pages/Profile/Performance/GoogleAds/GoogleAdsPrint'),
);

const SEOPrint = lazy(() => import('../../../pages/Profile/Performance/SEO/SEOPrint'));

const SocialMediaPrint = lazy(
  () => import('../../../pages/Profile/Performance/SocialMedia/SocialMediaPrint'),
);

const WebsitePrint = lazy(() => import('../../../pages/Profile/Performance/Websites/WebsitePrint'));

const performanceRoutes: NonIndexRouteObject[] = [
  {
    path: 'localsearch-plus',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LocalsearchPlus />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <LocalSearchPlusPrint />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: 'localsearch',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LocalsearchPlus />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <LocalSearchPlusPrint />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'localsearch/print',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LocalSearchPlusPrint />
      </Suspense>
    ),
  },
  {
    path: 'google-ads',
    element: (
      <Suspense fallback={<PageLoader />}>
        <GoogleAds />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <GoogleAdsPrint />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: 'seo',
    element: (
      <Suspense fallback={<PageLoader />}>
        <SEO />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <SEOPrint />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'social-media',
    element: (
      <Suspense fallback={<PageLoader />}>
        <SocialMedia />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <SocialMediaPrint />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'performance-boost',
    element: (
      <Suspense fallback={<PageLoader />}>
        <PerformanceBoost />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <PerformanceBoostPrint />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'website',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Websites />
      </Suspense>
    ),
    children: [
      {
        path: 'print',
        element: (
          <Suspense fallback={<PageLoader />}>
            <WebsitePrint />
          </Suspense>
        ),
      },
    ],
  },
];

export default performanceRoutes;
