import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import useLightbox, { LightboxValues } from './hooks/useLightbox';
import LightboxComponent from './Lightbox/LightboxComponent';

const LightboxContext = createContext<LightboxContextValues | Record<string, never>>({});

type LightboxContextValues = Pick<LightboxValues, 'openLightbox'>;

const LightboxContextProvider = ({ children }: PropsWithChildren) => {
  const { closeLightbox, isLightboxOpen, activeMediaIndex, options, media, ...other } =
    useLightbox();
  const value = useMemo(() => other, [other]);
  return (
    <LightboxContext.Provider value={value}>
      {children}
      <LightboxComponent
        show={isLightboxOpen}
        onClose={closeLightbox}
        index={activeMediaIndex}
        media={media}
        options={options}
      />
    </LightboxContext.Provider>
  );
};

/**
 * Provides lightbox context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   isLightboxOpen,
 *   openLightbox,
 *   closeLightbox
 * } = useLightboxContext();
 */
function useLightboxContext() {
  const context = useContext<LightboxContextValues | Record<string, never>>(LightboxContext);

  if (context === undefined) {
    throw new Error('useLightboxContext must be used within a LightboxProvider');
  }

  return context;
}

export default useLightboxContext;
export { LightboxContextProvider, LightboxContext };
