import { gql } from '@apollo/client';

const resendAwsAccountInvitation = gql`
  mutation ResendAwsAccountInvitation($input: ResendAwsAccountInvitationInput!) {
    resendAwsAccountInvitation(input: $input) {
      awsAccountInvitation {
        id
        name
        email
        awsAccountRoles {
          awsAccountId
          awsAccountRoleName
          profiles {
            totalCount
            edges {
              cursor
              node {
                id
                logo
              }
            }
          }
        }
        expiresAt
        createdAt
        updatedAt
      }
    }
  }
`;

export default resendAwsAccountInvitation;
