import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../UI/molecules/PageLoader';
import ErrorBoundary from '../../UI/molecules/ErrorBoundary';

const Overview = lazy(() => import('../../pages/Account/Overview'));
const Staff = lazy(() => import('../../pages/Account/Staff'));

const accountRoutes: NonIndexRouteObject[] = [
  {
    path: '',
    element: (
      <Suspense>
        <Overview />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: ':awsAccountId',
    element: (
      <Suspense>
        <Overview />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: ':awsAccountId/staff',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Staff />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export default accountRoutes;
