import useMarkAllNotificationsAsRead from './useMarkAllNotificationsAsRead';
import useMarkNotificationsAsRead from './useMarkNotificationsAsRead';
import useNotifications from './useNotifications';

const useNotificationsValues = () => {
  const notifications = useNotifications();
  const markNotificationsAsRead = useMarkNotificationsAsRead({
    notificationCounts: notifications.notificationCounts,
  });
  const markAllNotificationsAsRead = useMarkAllNotificationsAsRead();

  return {
    notifications,
    markNotificationsAsRead,
    markAllNotificationsAsRead,
  };
};

export default useNotificationsValues;
export type NotificationsValues = ReturnType<typeof useNotificationsValues>;
