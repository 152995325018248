import { gql } from '../../__generated__';

const profile = gql(`
  query profile($id: String!) {
    profile(id: $id) {
      ...ProfileFields
    }
  }
`);

export default profile;
