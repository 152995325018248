import { gql } from '@apollo/client';

const claimProfileComplete = gql`
  mutation claimProfileComplete($input: ClaimProfileCompleteInput!) {
    claimProfileComplete(input: $input) {
      profile {
        id
      }
    }
  }
`;

export default claimProfileComplete;
